import { BaseModel } from './base.model';
import { ValidateModel } from './validate.model';
import { Json } from '../modules';
import { MediaModel } from './media.model';

export class PostModel extends BaseModel {
  @Json("isEnable")
  public isEnable: boolean = undefined;

  @Json("title")
  public title: string = undefined;

  @Json("slug")
  public slug: string = undefined;

  @Json("shortDescription")
  public shortDescription: string = undefined;

  @Json("description")
  public description: string = undefined;

  @Json("type")
  public type: string = undefined;

  @Json("imageId")
  public imageId: string = undefined;

  @Json("metaKeyword")
  public metaKeyword: string = undefined;

  @Json("metaDescription")
  public metaDescription: string = undefined;


  @Json({ name: "image", clazz: MediaModel, omitEmpty: true })
  public image: MediaModel = undefined;


  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  /**
   * init validate rule
   * @returns {ValidateModel}
   */
  public initValidateRules(): ValidateModel {
    this.addRule('title', 'required', true, this._t('Tiêu đề không được bỏ trống.'));
    this.addRule('shortDescription', 'required', true, this._t('Mô tả ngắn không được bỏ trống.'));
    this.addRule('description', 'required', true, this._t('Nội dung không được bỏ trống.'));
    return this.getRules();
  }
}