import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { TransferHttp } from '../modules/transfer-http/transfer-http';
import { UtilHelper } from '../helpers';
import { REST_API, JsonMapper } from '../modules';
import { ResponseModel, PaginationModel } from '../models';
import { AppVersionModel } from '../models/app_version.model';

@Injectable()
export class AppVersionService extends BaseService {

    constructor(public http: TransferHttp) {
        super(http);
    }

    /**
     * Get list
     * @returns {Promise<T>|Promise<U>}
     */
    public findAll(paging: boolean = true, filter: any = {}): Promise<PaginationModel> {

        if (!paging) {
            filter.offset = '';
            filter.limit = '';
        }
        let queryString = UtilHelper.parseFilterToString(filter);
        return this.makeHttpGet(`${this.apiUrl}/` + REST_API.APP_VERSION + '?' + queryString)
            .then((res) => {
                res.data = JsonMapper.deserialize(AppVersionModel, res.data);
                return res;
            });
    }
    /**
     *
     * @param data
     */
    public findById(id: string): Promise<AppVersionModel> {
        return this.makeHttpGet(`${this.apiUrl}/` + REST_API.APP_VERSION + '/' + id)
            .then(res => JsonMapper.deserialize(AppVersionModel, res));
    }

    /**
     * edit
     * @returns {Promise<T>|Promise<U>}
     */
    public update(data: AppVersionModel): Promise<ResponseModel> {
        return this.makeHttpPut(`${this.apiUrl}/` + REST_API.APP_VERSION + '/' + data.id, JsonMapper.serialize(data));
    }

    /**
     * delete
     * @returns {Promise<T>|Promise<U>}
     */
    public delete(id: string): Promise<ResponseModel> {

        return this.makeHttpDelete(`${this.apiUrl}/` + REST_API.APP_VERSION + '/' + id);
    }
    /**
     * create
     * @returns {Promise<T>|Promise<U>}
     */
    public create(data: AppVersionModel): Promise<ResponseModel> {
        let obj = JSON.parse(JSON.stringify(data));
        obj = AppVersionModel.toRequest(data);
        return this.makeHttpPost(`${this.apiUrl}/` + REST_API.APP_VERSION, obj);
    }
}