import { BaseModel } from './base.model';
import { ValidateModel } from './validate.model';
import { Json } from '../modules';
import { UserModel } from './user.model';
import { MediaModel } from './media.model';
import { PromotionItemModel } from './promotion_item.model';

export class PromotionModel extends BaseModel {
  @Json("title")
  public title: string = undefined;

  @Json("isEnable")
  public isEnable: boolean = undefined;

  @Json({ name: "startDate" })
  public startDate: string = undefined;

  @Json({ name: "endDate" })
  public endDate: string = undefined;

  @Json({ name: "promotionItems", clazz: PromotionItemModel, omitEmpty: true })
  public promotionItems: PromotionItemModel[] = undefined;

  @Json({ name: "isAvailable" })
  public isAvailable: boolean = undefined;

  @Json({ name: "isRunning" })
  public isRunning: boolean = undefined;

  @Json({ name: "isEnded" })
  public isEnded: boolean = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  /**
   * init validate rule
   * @returns {ValidateModel}
   */
  public initValidateRules(): ValidateModel {
    this.addRule('title', 'required', true, this._t('Tên khuyến mãi không được bỏ trống.'));
    this.addRule('startDate', 'required', true, this._t('Ngày bắt đầu không được bỏ trống.'));
    this.addRule('endDate', 'required', true, this._t('Ngày kết thúc không được bỏ trống.'));
    return this.getRules();
  }
}