import { Component, OnInit, AfterContentInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MetaService } from '@ngx-meta/core';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { BaseComponent } from '../base.component';
import { UserModel } from '../../models';
import { UserIdentityService, AuthService, AppFireBaseService } from 'app/services';
import { ROLE, SESSION } from '../../modules';
import { ConfigService } from '@ngx-config/core';
import { ReduxAction } from '@harmowatch/ngx-redux-core';
import { SettingService } from '../../services/setting.service';

@Component({
    selector: 'app-login-page',
    templateUrl: './login.component.html'
})

export class LoginComponent extends BaseComponent implements OnInit, AfterContentInit {
    private user: UserModel;

    constructor(
        protected _router: Router,
        protected _route: ActivatedRoute,
        protected _meta: MetaService,
        protected _location: Location,
        protected _toastr: ToastrService,
        private config: ConfigService,
        private _authService: AuthService,
        private _firebaseService: AppFireBaseService,
        private _settingService: SettingService,
    ) {
        super(_router, _route, _meta, _location, _toastr);
    }

    public ngOnInit() {
        this.user = new UserModel();
        this.setPageTitle(this.pageTitle);

        let defaultLanguage = this.config.getSettings('i18n.defaultLanguage');
        const currentLanguage = sessionStorage.getItem(SESSION.LANGUAGE_KEYWORD);
        defaultLanguage = currentLanguage ? JSON.parse(currentLanguage) : defaultLanguage;
        sessionStorage.setItem(SESSION.LANGUAGE_KEYWORD, JSON.stringify(defaultLanguage));
    }

    public ngAfterContentInit() {
        if (UserIdentityService.isLoggedIn()) {
            const returnUrl = sessionStorage.getItem(SESSION.PREV_URL_KEYWORD);
            if (returnUrl) {
                this.navigateByUrl(returnUrl);
            } else {
                this.navigate(['/dashboard']);
            }
        }
    }

    public login() {
        try {
            if (this.user.validate('loginForm')) {
                let isValid: boolean = false;
                let userInfo: UserModel;
                let shopId: string;

                this._authService.login(this.user)
                    .then(response => {
                        if (response && response.user) {
                            userInfo = response.user;
                            switch (response.user.roleId) {
                                case ROLE.SYSTEM_ADMIN:
                                case ROLE.MANAGER:
                                    isValid = true;
                                    break;
                                case ROLE.OPERATOR:
                                case ROLE.CONTENT:
                                case ROLE.STORE_SUPPORT:
                                    if (response.user.shopId) {
                                        sessionStorage.setItem(SESSION.SHOP_ID, response.user.shopId);
                                        shopId = response.user.shopId;
                                    }
                                    isValid = true;
                                    break;
                                case ROLE.MERCHANT:
                                    sessionStorage.setItem(SESSION.SHOP_ID, response.user.id);
                                    shopId = response.user.id;
                                    isValid = true;
                                    break;
                                default:
                                    isValid = false;
                            }
                        }
                        if (!isValid) {
                            throw Error(this._t('You do not have permission to access.'));
                        } else {
                            UserIdentityService.setCredentials(response);
                            this.userLogin(response.user);
                            if (response && response.firebaseToken) {
                                return this._firebaseService.loginByToken(response.firebaseToken)
                                    .then(() => {
                                        return this._firebaseService.initListenData();
                                    });
                            }
                        }
                    })
                    .then(() => {
                        if (shopId) {
                            return this._settingService.getShopInfo()
                                .then(res => {
                                    sessionStorage.setItem(SESSION.SHOP_CONFIG, JSON.stringify(res));
                                });
                        }
                    })
                    .then(() => {
                        const returnUrl = this._route.snapshot.queryParams.return;
                        this.navigateByUrl(returnUrl ? returnUrl : '/dashboard');
                    })

                    .catch(error => {
                        this.setError(error);
                    });
            }
        } catch (error) {
            this.setError(error);
        }
    }

    @ReduxAction()
    public userLogin(user: UserModel) {
        return user;
    }
}
