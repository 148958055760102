/**
 * Created by phuongho on 10/15/16.
 */
import { BaseModel } from './base.model';
import { Json } from '../modules/mapper/json.decorator';
import { UtilHelper } from 'app/helpers';
import { ROLE, PROMOTION_TYPE} from 'app/modules';
export class OptionModel extends BaseModel {
  @Json('title')
  public title: any = undefined;

  @Json('keyword')
  public keyword: any = undefined;

  constructor() {
    super();
  }

  /**
   *
   * @param _title
   * @param _keyword
   * @returns {OptionModel}
   */
  public static init(_title: any, _keyword: any): OptionModel {
    const ret = new OptionModel();
    ret.title = UtilHelper.translate(_title);
    ret.keyword = _keyword;

    return ret;
  }

  public static getListRole(): OptionModel[] {

    const ret: OptionModel[] = [];
    ret.push(this.init('System Admin', ROLE.SYSTEM_ADMIN));
    ret.push(this.init('Manager', ROLE.MANAGER));

    return ret;
  }

  public static getPromotionStatus(): OptionModel[] {

    const ret: OptionModel[] = [];
    ret.push(this.init('Đang diễn ra', PROMOTION_TYPE.RUNNING));
    ret.push(this.init('Sắp diễn ra', PROMOTION_TYPE.AVAILABLE));
    ret.push(this.init('Kết thúc', PROMOTION_TYPE.ENDED));
    return ret;
  }
}
