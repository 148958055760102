import { UserModel } from './user.model';
import { BaseModel } from './base.model';
import * as moment from 'moment';
import { Json } from '../modules';
import { CategoryModel } from './category.model';

export class ReportProductModel extends BaseModel {
  @Json("reportDate")
  public reportDate: string = undefined;

  @Json("reportMonth")
  public reportMonth: number = undefined;

  @Json("reportYear")
  public reportYear: number = undefined;

  @Json("shopId")
  public shopId: string = undefined;

  @Json({ name: "shop", clazz: UserModel, omitEmpty: true })
  public shop: UserModel = undefined;

  @Json("categoryId")
  public categoryId: string = undefined;

  @Json({ name: "category", clazz: CategoryModel, omitEmpty: true })
  public category: CategoryModel = undefined;

  @Json("totalAmountAll")
  public totalAmountAll: number = 0;

  @Json("totalAmount")
  public totalAmount: number = 0;

  @Json("totalAmountRevenue")
  public totalAmountRevenue: number = 0;

  @Json("totalPriceRevenue")
  public totalPriceRevenue: number = 0;
}
