
import { BaseModel } from './base.model';
import { ValidateModel } from './validate.model';
import { Json } from '../modules';
import { MediaModel } from './media.model';
import { UserModel } from './user.model';

export class NotificationModel extends BaseModel {
    @Json("title")
    public title: string = undefined;

    @Json("shortDescription")
    public shortDescription: string = undefined;

    @Json("description")
    public description: string = undefined;

    @Json("type")
    public type: string = undefined;

    @Json("link")
    public link: string = undefined;

    @Json("target")
    public target: any[] = undefined;

    @Json("isEnable")
    public isEnable: boolean = undefined;

    @Json("isRead")
    public isRead: boolean = undefined;

    @Json("isSystem")
    public isSystem: boolean = undefined;

    @Json("userId")
    public userId: string = undefined;

    @Json("createdBy")
    public createdBy: string = undefined;

    @Json("itemId")
    public itemId: string = undefined;

    @Json("imageId")
    public imageId: string = undefined;

    @Json("clickAction")
    public clickAction: string = undefined;

    @Json({ name: "user", clazz: UserModel, omitEmpty: true })
    public user: UserModel = undefined;

    @Json({ name: "creator", clazz: UserModel, omitEmpty: true })
    public creator: UserModel = undefined;

    @Json({ name: "image", clazz: MediaModel, omitEmpty: true })
    public image: MediaModel = undefined;

    constructor() {
        super();
        this.validateRules = new ValidateModel();
        this.initValidateRules();

    }

    /**
     * init validate rule
     * @returns {ValidateModel}
     */
    public initValidateRules(): ValidateModel {

        this.addRule('title', 'required', true, this._t('Tiêu đề không được bỏ trống.'));
        this.addRule('shortDescription', 'required', true, this._t('Mô tả ngắn không được bỏ trống.'));
        this.addRule('link', 'required', true, this._t('Đường dẫn không được bỏ trống.'));
        // this.addRule('description', 'required', true, this._t('Mô tả không được bỏ trống.'));
        this.addRule('type', 'required', true, this._t('Loại thông báo không được bỏ trống.'));
        this.addRule('target', 'required', true, this._t('Target không được bỏ trống.'));

        return this.getRules();
    }

}