import { BaseModel } from "./base.model";
import { Json } from "app/modules";
import { ValidateModel } from "./validate.model";

export class PointConfigModel extends BaseModel {
    @Json("moneyToPoint")
    public moneyToPoint: number = undefined;

    @Json("pointToMoney")
    public pointToMoney: number = undefined;

    @Json("expired")
    public expired: number = undefined;

    constructor() {
        super();
        this.validateRules = new ValidateModel();
        this.initValidateRules();
    }

    public initValidateRules(): ValidateModel {
        this.addRule('expired', 'required', true, this._t('Ngày hết hạn không được để trống.'));
        this.addRule('pointToMoney', 'required', true, this._t('Tỉ lệ qui đổi sang VNĐ không được để trống.'));
        this.addRule('moneyToPoint', 'required', true, this._t('Tỉ lệ qui đổi sang điểm không được để trống.'));

        return this.getRules();
    }
}
