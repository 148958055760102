import { BaseModel } from './base.model';
import { ValidateModel } from './validate.model';
import { Json } from '../modules';

export class ColorModel extends BaseModel {

  @Json('name')
  public name: string = undefined;

  @Json('code')
  public code: string = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  /**
   * init validate rule
   * @returns {ValidateModel}
   */
  public initValidateRules(): ValidateModel {
    this.addRule('name', 'required', true, this._t('Tên không được bỏ trống.'));
    this.addRule('code', 'required', true, this._t('Mã màu không được bỏ trống.'));

    return this.getRules();
  }
}