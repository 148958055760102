import { JsonMapper } from '../modules/mapper/json.mapper';
import { TransferHttp } from '../modules/transfer-http/transfer-http';
import { UtilHelper } from '../helpers/util.helper';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BaseService } from './base.service';
import { PaginationModel, SettingModel, ResponseModel, GeneralConfigModel } from '../models';
import { REST_API, SETTING_KEYWORD } from '../modules/constants';

@Injectable()
export class SettingService extends BaseService {

    constructor(public http: TransferHttp) {
        super(http);
    }

    /**
     * Get list variable
     * @returns {Promise<T>|Promise<U>}
     */
    public findAll(paging: boolean = true, filter: any = {}): Promise<PaginationModel> {

        if (!paging) {
            filter.offset = '';
            filter.limit = '';
        }
        const queryString = UtilHelper.parseFilterToString(filter);
        return this.makeHttpGet(`${this.apiUrl}/` + REST_API.SETTING + '?' + queryString)
            .then((res) => {
                res.data = JsonMapper.deserialize(SettingModel, res.data);
                return res;
            });
    }

    /**
   * Get detail
   * @param id
   */
    public findById(id: string): Promise<SettingModel> {
        return this.makeHttpGet(`${this.apiUrl}/${REST_API.SETTING}/${id}`)
            .then((res) => {
                return JsonMapper.deserialize(SettingModel, res);
            });
    }

    /**
     * Create
     * @param data
     */
    public create(data: SettingModel): Promise<ResponseModel> {
        return this.makeHttpPost(`${this.apiUrl}/${REST_API.SETTING}`, JsonMapper.serialize(data));
    }
    /**
     * Update
     * @param data
     */

    public update(data: SettingModel): Promise<ResponseModel> {
        return this.makeHttpPut(`${this.apiUrl}/${REST_API.SETTING}/${data.id}`, JsonMapper.serialize(data));
    }

    public delete(id: string): Promise<ResponseModel> {
        return this.makeHttpDelete(`${this.apiUrl}/${REST_API.SETTING}/${id}`);
    }

    public getShopInfo(): Promise<GeneralConfigModel> {
        return this.makeHttpGet(`${this.apiUrl}/${REST_API.SETTING}` + '/shop-info')
            .then((res) => {
                const obj =  JsonMapper.deserialize(SettingModel, res);
                return JsonMapper.deserialize(GeneralConfigModel, obj.value);
            });
    }
}