import { ROLE } from '../../modules/constants';
import {
  MIN_AUTO_COMPLETE_FILTER_CHAR,
  DEPLAY_AUTO_COMPLETE_FILTER_CHAR
} from '../../modules/constants';
import {
  Component,
  ViewEncapsulation,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  forwardRef
} from '@angular/core';
import { distinctUntilChanged, debounceTime, switchMap, filter } from 'rxjs/operators';
import { Subject, of, concat } from 'rxjs';
import { Router, ActivatedRoute, Event } from '@angular/router';
import {
  UserService
} from '../../services';
import {
  UserModel
} from '../../models';
import { BaseComponent } from '../../pages/base.component';
import { MetaService } from '@ngx-meta/core';
import { Location } from '@angular/common';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-filter',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './user_filter.component.html',
  providers: [
    UserService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserFilterComponent),
      multi: true
    }
  ]
})

export class UserFilterComponent extends BaseComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Output() change: EventEmitter<UserModel> = new EventEmitter<UserModel>();
  @Input() placeholder: string;
  @Input() search: any = {};
  public user: UserModel;
  public users: any;
  public filterInput = new Subject<string>();
  private propagateChange = (_: any) => { };
  // tslint:disable-next-line:member-ordering
  public defaultList: UserModel[];

  constructor(
    protected _router: Router,
    protected _route: ActivatedRoute,
    protected _meta: MetaService,
    protected _location: Location,
    protected _toastr: ToastrService,
    protected userService: UserService,
  ) {
    super(_router, _route, _meta, _location, _toastr);
  }

  ngOnInit() {
    this.user = new UserModel();
    this.defaultList = [];
    this.placeholder = this.placeholder ? this.placeholder : this._t('Nhập từ khoá tìm kiếm');
    // this.userService.findAll(false)
    //   .then(res => {
    //     this.defaultList = res.data;
    //     this.initAutoComplete();
    //   });
  }

  ngOnDestroy() {
  }

  writeValue(value: any) {
    if (value) {
      this.user = value;
    } else {
      this.user = new UserModel();
    }
    this.initAutoComplete();

  }
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any) {
  }

  initAutoComplete() {
    this.users = concat(
      of((this.user.id ? [this.user] : this.defaultList)), // default items
      this.filterInput.pipe(
        debounceTime(DEPLAY_AUTO_COMPLETE_FILTER_CHAR),
        filter(val => val != null && val !== '' && val.length >= MIN_AUTO_COMPLETE_FILTER_CHAR),
        distinctUntilChanged(),
        switchMap(keyword => {
          this.search.key = keyword;
          this.search.isEnable = 1;
          this.search.roleId = this.search.roleId ? this.search.roleId : ROLE.USER;
          return this.userService.findAll(false, this.search)
            .then(res => res.data)
            .catch((err) => of([]));
        })));
  }

  /**
   *
   * @param data
   */
  public onChange(data: UserModel) {
    const value = (data && data.id) ? data.id : '';
    this.change.emit(data);
    this.propagateChange(value);
    if (!data) {
      this.user = new UserModel();
      this.initAutoComplete();
    }
  }
}