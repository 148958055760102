/**
 * Created by phuongho on 10/14/16.
 */
import { Json } from '../modules';

export class ResponseModel {
  @Json('title')
  public title: string = undefined;

  @Json('code')
  public code: number = undefined;

  @Json('message')
  public message: string = undefined;

  @Json('value')
  public value: any = undefined;

  /**
   *
   * @param code
   * @param message
   * @param value
   * @param title
   * @returns {ResponseModel}
   */
  public static init(code, message, value: any = {}, title: string = ''): ResponseModel {
    const ret: ResponseModel = new ResponseModel();
    ret.code = code;
    ret.value = value;
    ret.message = message;
    ret.title = title;

    return ret;
  }
}