/**
 * Created by phuongho on 7/23/16.
 */
import { Directive, ElementRef, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { DATETIMEPICKER_FORMAT, MOMENT_DATE_FORMAT } from '../../modules';
import { DateHelper } from '../../helpers/date.helper';

@Directive({
    selector: '[datetimepicker]',
})

export class DateTimePickerDirective implements OnInit {
    private htmlElement: HTMLElement;

    @Input('format') format: string = DATETIMEPICKER_FORMAT.YYYY_MM_DD;
    @Input('scrollMonth') scrollMonth: boolean = false;
    @Input('scrollInput') scrollInput: boolean = false;
    @Input('validateOnBlur') validateOnBlur: boolean = true;
    @Input('formatDate') formatDate: string = DATETIMEPICKER_FORMAT.YYYY_MM_DD;
    @Input('formatTime') formatTime: string = 'H:i';
    @Input('datepicker') datepicker: boolean = true;
    @Input('timepicker') timepicker: boolean = false;
    @Input('minDate') minDate: Date | string | boolean = false;
    @Input('maxDate') maxDate: Date | string | boolean;
    @Input('mask') mask: boolean = false;
    @Input('step') step: number = 5;
    @Input('value') value: string | Date;
    @Input('disabledWeekDays') disabledWeekDays: number[] = [];
    @Input('disabledDates') disabledDates: string[] = [];
    @Output() ngModelChange: EventEmitter<any> = new EventEmitter(false);
    @Output() change: EventEmitter<any> = new EventEmitter<any>();

    constructor(private el: ElementRef) {
        this.htmlElement = el.nativeElement;
    }

    public ngOnInit(): any {
        jQuery.datetimepicker.setLocale('vi');
        jQuery(this.htmlElement).datetimepicker({
            format: this.format,
            scrollMonth: this.scrollMonth,
            scrollInput: this.scrollInput,
            validateOnBlur: this.validateOnBlur,
            formatDate: this.formatDate,
            formatTime: this.formatTime,
            datepicker: this.datepicker,
            timepicker: this.timepicker,
            minDate: this.minDate,
            maxDate: this.maxDate,
            mask: this.mask,
            step: this.step,
            value: this.value,
            disabledWeekDays: this.disabledWeekDays,
            disabledDates: this.disabledDates,
            onSelectDate: (date) => {
                const format = this.getFormat(MOMENT_DATE_FORMAT.YYYY_MM_DD);
                const newDate = DateHelper.toFormat(date, format);

                this.ngModelChange.emit(newDate);
                this.change.emit(newDate);
            },
            onSelectTime: (date) => {
                const format = this.getFormat(MOMENT_DATE_FORMAT.YYYY_MM_DD);
                const newDate = DateHelper.toFormat(date, format);

                this.ngModelChange.emit(newDate);
                this.change.emit(newDate);
            }
        });
    }

    public getFormat(defaultFomat: string) {
        let format = defaultFomat ? defaultFomat : DATETIMEPICKER_FORMAT.YYYY_MM_DD;

        switch (this.format) {
            case DATETIMEPICKER_FORMAT.YYYY_MM_DD:
                format = MOMENT_DATE_FORMAT.YYYY_MM_DD;
                break;

            case DATETIMEPICKER_FORMAT.DD_MMM_YY:
                format = MOMENT_DATE_FORMAT.DD_MMM_YY;
                break;

            case DATETIMEPICKER_FORMAT.DD_MMM_YY_H_i:
                format = MOMENT_DATE_FORMAT.DD_MMM_YY_H_m;
                break;

            case DATETIMEPICKER_FORMAT.MM_DD_YYYY:
                format = MOMENT_DATE_FORMAT.MM_DD_YYYY;
                break;

            case DATETIMEPICKER_FORMAT.DD_MM_YYYY:
                format = MOMENT_DATE_FORMAT.DD_MM_YYYY;
                break;
            case DATETIMEPICKER_FORMAT.YYYY_MM_DD_H_m:
                format = MOMENT_DATE_FORMAT.YYYY_MM_DD_H_m;
                break;
            default:
                format = defaultFomat;
        }

        return format;
    }
}
