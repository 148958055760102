import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MetaService } from '@ngx-meta/core';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { ConfigService } from '@ngx-config/core';
import { ROLE, SESSION } from 'app/modules';
import { BaseComponent } from 'app/pages/base.component';
import { UserIdentityService, AuthService, AppFireBaseService, NotificationService } from 'app/services';
import { ReduxAction } from '@harmowatch/ngx-redux-core';
import { UserModel } from 'app/models';
import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { LayoutService } from '../services/layout.service';
import { Subscription } from 'rxjs';
import ConfigThemeService from '../services/config.service';
import { DEFAULT_SHOP_ID } from '../../modules/constants';

@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"],
    providers: [
        AuthService,
        NotificationService,
        AppFireBaseService
    ]
})
export class NavbarComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    currentLang = "en";
    toggleClass = "ft-maximize";
    placement = "bottom-right";
    public isCollapsed = true;
    layoutSub: Subscription;
    @Output()
    toggleHideSidebar = new EventEmitter<Object>();

    public config: any = {};
    public languages: any = [];
    public totalNotification: number = 0;
    public ROLE = ROLE;
    public shopId: string = DEFAULT_SHOP_ID;
    public supplier: UserModel;

    constructor(
        public translate: TranslateService,
        private layoutService: LayoutService,
        private configService: ConfigThemeService,
        protected _router: Router,
        protected _route: ActivatedRoute,
        protected _meta: MetaService,
        protected _location: Location,
        protected _toastr: ToastrService,
        private setting: ConfigService,
        private authService: AuthService,
        protected _firebaseService: AppFireBaseService,
        protected _notificationService: NotificationService,
    ) {
        super(_router, _route, _meta, _location, _toastr);

        this.languages = this.setting.getSettings('i18n.availableLanguages');

        this.layoutSub = layoutService.changeEmitted$.subscribe(
            direction => {
                const dir = direction.direction;
                if (dir === "rtl") {
                    this.placement = "bottom-left";
                } else if (dir === "ltr") {
                    this.placement = "bottom-right";
                }
            });
    }

    ngOnInit() {
        this.config = this.configService.templateConf;
        this.getNotificationInfo(this.profile.id);
    }

    ngAfterViewInit() {
        if (this.config.layout.dir) {
            setTimeout(() => {
                const dir = this.config.layout.dir;
                if (dir === "rtl") {
                    this.placement = "bottom-left";
                } else if (dir === "ltr") {
                    this.placement = "bottom-right";
                }
            }, 0);

        }
        const currentSupplier = sessionStorage.getItem(SESSION.SHOP_INFO);
        if (currentSupplier) {
            this.supplier = JSON.parse(currentSupplier);
        }
    }

    ngOnDestroy() {
        if (this.layoutSub) {
            this.layoutSub.unsubscribe();
        }
    }

    ChangeLanguage(language: string) {
        this.translate.use(language);
    }

    ToggleClass() {
        if (this.toggleClass === "ft-maximize") {
            this.toggleClass = "ft-minimize";
        } else {
            this.toggleClass = "ft-maximize";
        }
    }

    toggleNotificationSidebar() {
        this.layoutService.emitNotiSidebarChange(true);
    }

    toggleSidebar() {
        const appSidebar = document.getElementsByClassName("app-sidebar")[0];
        if (appSidebar.classList.contains("hide-sidebar")) {
            this.toggleHideSidebar.emit(false);
        } else {
            this.toggleHideSidebar.emit(true);
        }
    }
    public logout() {
        try {
            return Promise.resolve()
                .then(() => {
                    return this.authService.logout();
                })
                .then(() => {
                    UserIdentityService.clearCredentials();
                    this.userLogout();
                    this.navigate(['/login']);
                })
                .catch(error => {
                    this.setError(error);
                });
        } catch (error) {
            this.setError(error);
        }
    }

    @ReduxAction()
    public userLogout() {
        return new UserModel;
    }

    private changeLanguage(language: any): any {
        this.translate.use(language.code).subscribe(() => {
            this._meta.setTag('og:locale', language.culture);
            sessionStorage.setItem(SESSION.LANGUAGE_KEYWORD, JSON.stringify(language));
            this.currentLanguage = language;
        });
    }

    public getNotificationInfo(profileId) {
        return Promise.resolve()
            .then(() => {
                return this._firebaseService.getInbox(profileId);
            })
            .then(snapshot => {
                this._firebaseService.subscriptions[Date.now()] = snapshot.subscribe(item => {
                    if (item) {
                        this.totalNotification = parseInt(item.notification);
                    }
                });
            });
    }

    public readAll() {
        try {
            return this._notificationService.readAll()
                .then((response) => {
                    this.findAll();
                    this.setSuccess(this._t(response.message));
                })
                .catch((error) => {
                    this.setError(error);
                });
        } catch (error) {
            this.setError(error);
        }
    }

    selectSupplier(user) {
        if (user) {
            sessionStorage.setItem(SESSION.SHOP_ID, user.id);
            sessionStorage.setItem(SESSION.SHOP_INFO, JSON.stringify(user));
        } else {
            sessionStorage.removeItem(SESSION.SHOP_ID);
            sessionStorage.removeItem(SESSION.SHOP_INFO);
        }
        this.navigate(["/dashboard"]);
    }

    checkShowListSupplier() {
        let result: boolean = false;
        switch (this.profile.roleId) {
            case ROLE.SYSTEM_ADMIN:
            case ROLE.MANAGER:
                result = true;
                break;
        }
        return result;
    }
}