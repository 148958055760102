import { BaseModel } from "./base.model";
import { UserModel } from "./user.model";
import { WarehousePlanningDetailModel } from "./warehouse_planning_detail.model";
import { Json } from "app/modules";
import { ValidateModel } from "./validate.model";

export class WarehousePlanningModel extends BaseModel {

    @Json("isEnable")
    public isEnable: boolean = undefined;

    @Json("type")
    public type: string = undefined;

    @Json("quantityDesc")
    public quantityDesc: string = undefined;

    @Json("note")
    public note: string = undefined;

    @Json("createdBy")
    public createdBy: string = undefined;

    @Json({ name: "creator", clazz: UserModel, omitEmpty: true })
    public creator: UserModel = undefined;

    @Json({ name: "planningDetail", clazz: WarehousePlanningDetailModel, omitEmpty: true })
    public planningDetail: WarehousePlanningDetailModel[] = undefined;

    constructor() {
        super();
        this.validateRules = new ValidateModel();
        this.initValidateRules();
      }

      /**
       * init validate rule
       * @returns {ValidateModel}
       */
      public initValidateRules(): ValidateModel {
        this.addRule('type', 'required', true, this._t('Hoạt động không được bỏ trống.'));
        return this.getRules();
      }
}

