import { UtilHelper } from 'app/helpers';
import { Json } from '../modules';
import { BaseModel } from './base.model';
import { ValidateModel } from './validate.model';

export class PaymentConfigModel extends BaseModel {
    @Json("internalMerchant")
    public internalMerchant: string = undefined;

    @Json("internalUser")
    public internalUser: string = undefined;

    @Json("internalPassword")
    public internalPassword: string = undefined;

    @Json("internalAccessCode")
    public internalAccessCode: string = undefined;

    @Json("internalSecretKey")
    public internalSecretKey: string = undefined;

    @Json("internationalMerchant")
    public internationalMerchant: string = undefined;

    @Json("internationalUser")
    public internationalUser: string = undefined;

    @Json("internationalPassword")
    public internationalPassword: string = undefined;

    @Json("internationalAccessCode")
    public internationalAccessCode: string = undefined;

    @Json("internationalSecretKey")
    public internationalSecretKey: string = undefined;

    @Json("title")
    public title: string = undefined;

    @Json("returnUrl")
    public returnUrl: string = undefined;

    @Json("sandbox")
    public sandbox: boolean = undefined;

    @Json("source")
    public source: string = undefined;

    constructor() {
        super();
        this.validateRules = new ValidateModel();
        this.initValidateRules();
    }

    public initValidateRules(): ValidateModel {
        this.addRule('internalMerchant', 'required', true, this._t('Thông tin không được để trống.'));
        this.addRule('internalUser', 'required', true, this._t('Thông tin không được để trống.'));
        this.addRule('internalPassword', 'required', true, this._t('Thông tin không được để trống.'));
        this.addRule('internalAccessCode', 'required', true, this._t('Thông tin không được để trống.'));
        this.addRule('internalSecretKey', 'required', true, this._t('Thông tin không được để trống.'));
        this.addRule('internationalMerchant', 'required', true, this._t('Thông tin không được để trống.'));
        this.addRule('internationalUser', 'required', true, this._t('Thông tin không được để trống.'));
        this.addRule('internationalPassword', 'required', true, this._t('Thông tin không được để trống.'));
        this.addRule('internationalAccessCode', 'required', true, this._t('Thông tin không được để trống.'));
        this.addRule('internationalSecretKey', 'required', true, this._t('Thông tin không được để trống.'));
        this.addRule('title', 'required', true, this._t('Thông tin không được để trống.'));
        this.addRule('returnUrl', 'required', true, this._t('Thông tin không được để trống.'));
        this.addRule('source', 'required', true, this._t('Thông tin không được để trống.'));

        return this.getRules();
    }

    public static toRequest(input: PaymentConfigModel) {
        let output: any = {};

        output.internalMerchant = UtilHelper.encryptRSA(input.internalMerchant);
        output.internalUser = UtilHelper.encryptRSA(input.internalUser);
        output.internalPassword = UtilHelper.encryptRSA(input.internalPassword);
        output.internalAccessCode = UtilHelper.encryptRSA(input.internalAccessCode);
        output.internalSecretKey = UtilHelper.encryptRSA(input.internalSecretKey);
        output.internationalMerchant = UtilHelper.encryptRSA(input.internationalMerchant);
        output.internationalUser = UtilHelper.encryptRSA(input.internationalUser);
        output.internationalPassword = UtilHelper.encryptRSA(input.internationalPassword);
        output.internationalAccessCode = UtilHelper.encryptRSA(input.internationalAccessCode);
        output.internationalSecretKey = UtilHelper.encryptRSA(input.internationalSecretKey);
        output.title = UtilHelper.getString(input.title);
        output.returnUrl = UtilHelper.getString(input.returnUrl);
        output.source = UtilHelper.getString(input.source);
        output.sandbox = UtilHelper.getBoolean(input.sandbox);

        return output;

    }
}