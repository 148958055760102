import { BaseModel } from './base.model';
import { ValidateModel } from './validate.model';
import { Json } from '../modules';
import { MediaModel } from './media.model';

export class BannerModel extends BaseModel {

  @Json("title")
  public title: string = undefined;

  @Json("imageId")
  public imageId: string = undefined;

  @Json("link")
  public link: string = undefined;

  @Json({ name: "image", clazz: MediaModel, omitEmpty: true })
  public image: MediaModel = undefined;


  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  /**
   * init validate rule
   * @returns {ValidateModel}
   */
  public initValidateRules(): ValidateModel {
    this.addRule('name', 'required', true, this._t('Tên không được bỏ trống.'));
    this.addRule('link', 'url', true, this._t('Link không đúng định dạng.'));

    return this.getRules();
  }
}