import { BaseModel } from './base.model';
import { ValidateModel } from './validate.model';
import { Json } from '../modules';

export class PostCategoryModel extends BaseModel {

  @Json('name')
  public name: string = undefined;

  @Json('priority')
  public priority: number = undefined;

  @Json('isEnable')
  public isEnable: boolean = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  /**
   * init validate rule
   * @returns {ValidateModel}
   */
  public initValidateRules(): ValidateModel {
    this.addRule('name', 'required', true, this._t('Tên không được bỏ trống.'));

    return this.getRules();
  }
}