import { JsonMapper } from '../modules/mapper/json.mapper';
import { TransferHttp } from '../modules/transfer-http/transfer-http';
import { Injectable } from '@angular/core';

import { MediaModel } from '../models';
import { HTTP_METHOD } from '../modules/constants';
import * as  _ from 'lodash';
import { BaseService } from './base.service';

@Injectable()
export class MediaService extends BaseService {

    constructor(protected _http: TransferHttp) {
        super(_http);
    }

    /**
     * Upload image
     * @param url
     * @param data
     * @returns {Promise<TResult>}
     */

    private uploadMedia(url: string, data: MediaModel): Promise<MediaModel> {
        const model = JsonMapper.serialize(data);
        const obj = this.toUploadFields(model);
        return this.makeHttpRequest(HTTP_METHOD.POST, url, obj)
            .then((res) => {
                const obj = JsonMapper.deserialize(MediaModel, res);
                if (res.metadata && res.metadata.exif && res.metadata.exif.size) {
                    obj.width = res.metadata.exif.size.width;
                    obj.height = res.metadata.exif.height;
                    obj.orientation = res.metadata.exif.orientation;
                }
                return obj;
            });
    }

    /**
     *
     * @param data
     * @returns {Promise<MediaModel>}
     */
    public uploadImage(data: MediaModel): Promise<MediaModel> {
        return this.uploadMedia(`${this.mediaUrl}/images`, data);
    }

    public uploadFile(data: MediaModel): Promise<MediaModel> {
        return this.uploadMedia(`${this.mediaUrl}/file`, data);
    }
}