import { BaseModel } from "./base.model";
import { ProductSizeModel } from "./product_size.model";
import { Json } from "app/modules";

export class WarehousePlanningDetailModel extends BaseModel {
    @Json("planningId")
    public planningId: string = undefined;

    @Json("productId")
    public productId: string = undefined;

    @Json("productSizeId")
    public productSizeId: string = undefined;

    @Json("quantity")
    public quantity: number = undefined;

    @Json({ name: "productSize", clazz: ProductSizeModel, omitEmpty: true })
    public productSize: ProductSizeModel = undefined;

}
