import { BaseModel } from './base.model';
import { ValidateModel } from './validate.model';
import { Json } from '../modules';

export class PointTypeModel extends BaseModel {

  @Json("name")
  public name: string = undefined;

  @Json("amount")
  public amount: number = undefined;

  @Json("discount")
  public discount: number = undefined;

  @Json("description")
    public description: string = undefined;

  @Json("keyword")
  public keyword: number = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  /**
   * init validate rule
   * @returns {ValidateModel}
   */
  public initValidateRules(): ValidateModel {
    this.addRule('name', 'required', true, this._t('Tên không được bỏ trống.'));
    this.addRule('description', 'required', true, this._t('Mô tả không được bỏ trống.'));
    this.addRule('keyword', 'required', true, this._t('Từ khoá không được bỏ trống.'));
    this.addRule('amount', 'required', true, this._t('Tổng giá trị mua hàng không được bỏ trống.'));
    this.addRule('discount', 'required', true, this._t('Chiết khấu không được bỏ trống.'));
    this.addRule('discount', 'numeric', true, this._t('Chiết khấu phải là số.'));
    this.addRule('unit', 'numeric', true, this._t('Chiết khấu phải là số.'));

    return this.getRules();
  }
}