/**
 * Created by phuongho on 10/14/16.
 */
export const HTTP_METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
};

export const AUTHORIZATION = {
  TYPE: 'Authorization',
  METHOD: 'Bearer'
};

export const COOKIE = {
  FIREBASE_TOKEN_KEYWORD: 'firebase_token',
  TOKEN_KEYWORD: 'token',
  PROFILE_KEYWORD: 'profile',
  LANGUAGE_KEYWORD: 'language',
  SHOP_ID: 'shop-id',
};

export const SESSION = {
  FIREBASE_TOKEN_KEYWORD: 'firebase_token',
  TOKEN_KEYWORD: 'token',
  PROFILE_KEYWORD: 'profile',
  LANGUAGE_KEYWORD: 'language',
  NEXT_URL_KEYWORD: 'nextUrl',
  PREV_URL_KEYWORD: 'prevUrl',
  SHOP_ID: 'shopId',
  SHOP_INFO: 'shopInfo',
  SHOP_CONFIG: 'shopConfig',
};

export const ERROR_CODE = {
  AUTHENTICATION: {
    GENERIC: 1100,
    VIOLATE_RFC6750: 1101,
    TOKEN_NOT_FOUND_CODE: 1102,
    NOT_AUTHORIZED_CODE: 1103,
    NOT_PERMISSION_ACCESS_CODE: 1104,
    WRONG_USER_OR_PASSWORD_CODE: 1105,
    INVALID_ACCESS_TOKEN_CODE: 1106,
    TOKEN_EXPIRED_CODE: 1107,
  },
  UNKNOWN: {
    MAINTENANCE_PERIOD: 3,
  }
};
export const ROLE = {
  SYSTEM_ADMIN: 'SystemAdmin',
  MANAGER: 'Manager',
  USER: 'User',
  OPERATOR: "Operator",
  CONTENT: 'Content',
  STORE_SUPPORT: 'StoreSupport',
  MERCHANT: 'Merchant',
};

export const PAGINATION = {
  MAX_SIZE: 5,
  ITEMS_PER_PAGE: 20,
  PAGE_ITEM_SIZE: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 100],
  BT_BUSINESS_ITEMS_PER_PAGE: 10,
  BT_AGENCY_ITEMS_PER_PAGE: 10,
  ITEM_PRODUCT_FILTER_PAGE: 24,
  DASHBOARD_ITEMS_PER_PAGE: 5
};

export const HEADERS = {
  TOTAL_ITEMS: 'Total',
  ITEM_PER_PAGE: 'Item-Per-Page',
  CONTENT_DISPOSITION: 'Content-Disposition',
  CONTENT_TYPE: 'Content-Type',
  STATUS_CODE_SUCCESS: 200,
  DEVICE_ID: 'device-id',
  REGISTRAR_ID: 'registrar-id',
  USER_AGENT: 'userAgent',
  DEVICE_OS: 'device-os',
  APP_VERSION: 'app-version',
  LANGUAGE: 'language',
  TIMEZONE: 'timezone',
  SHOP_ID: 'shop-id',
};

export const HTTP = {
  METHOD: {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
  },
  CONTENT_TYPE: {
    JSON: 'application/json; charset=utf-8'
  },
  HEADER: {
    DEVICE_ID: 'website',
    DEVICE_OS: 'browsers',
    APP_VERSION: '1.0.0',
    USER_AGENT: 'browser',
  }
};

export const REST_API = {
  AUTH: {
    LOGIN: `auth/admin/login`,
    FORGOT_PASSWORD: `auth/admin/forgot-password`,
    RESET_PASSWORD: `auth/reset-password`,
    LOGOUT: `auth/logout`
  },
  USER: `users`,
  ME: {
    PROFILE: `me`,
    CHANGE_PASSWORD: `me/password`,
    FIREBASE_TOKEN: `auth/firebase`,
    UPDATE_DEVICE: `me/devices`,
  },
  ROLE: `roles`,
  MEDIA: `media`,
  FILE: `media/files`,
  SETTING: `settings`,
  CATEGORY: `categories`,
  COLOR: `colors`,
  SIZE: `sizes`,
  PRODUCT: `products`,
  POINT_TYPE: `point-types`,
  POINT: `points`,
  WAREHOUSE_PLANNING: `warehouse-planning`,
  WAREHOUSE_TRACKING: `warehouse-tracking`,
  BANNER: `banners`,
  PROMOTION_CODE: `promotion-codes`,
  PROMOTION: `promotions`,
  ORDER: `orders`,
  ADDRESS_BOOK: `address-books`,
  POST_CATEGORY: `post-categories`,
  POST: `posts`,
  LOCATION: {
    CITY: "locations/cities",
    DISTRICT: "locations/districts",
    WARD: "locations/wards",
  },
  NOTIFICATION: {
    LIST: `notifications`,
    DETAIL: `notifications`,
    CREATE: `notifications`,
    UPDATE: `notifications`,
    DELETE: `notifications`,
    UPDATE_READ: `notifications/read`,
    READ_ALL: `notifications/read_all`
  },
  DASHBOARD: {
    COUNT_TOTAL: `reports/dashboard/count-total`,
    COUNT_ORDER: `reports/dashboard/count-order`,
    RECENTLY_SALE_ITEMS: `reports/dashboard/recently-sale`,
    TOP_SALE_ITEMS: `reports/dashboard/top-sale`,
    REVENUE_BY_YEAR: `reports/dashboard/revenue`
  },
  REPORT: {
    ORDER_USER: `reports/orders/user`,
    ORDER_PRODUCT: `reports/orders/product`,
    ORDER_LOCATION: `reports/orders/location`,
    USER: `reports/user`,
    PRODUCT: `reports/product`,
    RESET: `reports/reset`
  },
  APP_VERSION: "application"
};

export const IMAGE_ORIENTATION = {
  PORTRAIT: 'Portrait',
  LANDSCAPE: 'Landscape'
};

export const MAX_SIZE_UPLOAD = 10; // 10 MB
export const IMAGE_EXTENSION = 'jpg|jpeg|png|JPG|JPEG|PNG';
export const DOCUMENT_EXTENSION = 'pdf|PDF';
export const FILE_EXTENSION = 'csv|xls|xlsx';
export const ZIP_EXTENSION = 'zip';
export const MAX_TITLE = 255;
export const MAX_DESC = 2500;
export const MAX_LENGTH_PHONE = 11;
export const MAX_LENGTH_NUMBER = 255;
export const MAX_LENGTH_PASSWORD = 255;

export const MOMENT_DATE_FORMAT = {
  YYYY_MM_DD: 'YYYY-MM-DD',
  DD_MMM_YY: 'DD MMM YY',
  DD_MMM_YY_H_m: 'DD MMM YY H:m',
  MM_DD_YYYY: 'MM-DD-YYYY',
  DD_MM_YYYY: 'DD-MM-YYYY',
  YYYY_MM_DD_H_m: 'YYYY-MM-DD HH:mm',
  MM_DD_YYYY_H_m: 'MM-DD-YYYY HH:mm',
  DD_MM_YYYY_H_m: 'DD-MM-YYYY HH:mm',
  DD_MMMM_YYYY_hh_mm_A: 'DD MMMM YYYY, HH:mm A',
  HH_MM: 'HH:mm',
  h_mm_a: 'hh:mm A',
  MM_YYYY: 'MMM-YYYY',
  SEARCH_CAR: 'DD MMMM',
};

export const TIME_ZONE = {
  TIME_ZONE_DEFAULT: 'Asia/Ho_Chi_Minh',
  TIME_ZONE_UTC: 'UTC',
  VIETNAM: 'Asia/Ho_Chi_Minh',
};

export const LANGUAGE = {
  ENGLISH: 'en',
  VIETNAM: 'vi',
};

export const HTTP_CONNNECTION_TIMEOUT = 300000; // 5 minutes

export const MESSAGE_INFO = {
  MI_HTTP_CONNNECTION_TIMEOUT: 'Connection is timeout exceeded',
  MI_CHAT_SESSION_STOP: 'The chat session has been stopped',
};

export const DATEPICKER_FORMAT = {
  YYYY_MM_DD: 'yyyy-mm-dd',
};

export const DELETE_STATUS = {
  NO: false,
  YES: true
};

export const ENABLE_STATUS = {
  NO: false,
  YES: true
};

export const INPUT_MAXLENGTH = 255; // characters
export const INPUT_PASSWORD_MAXLENGTH = 6; // characters

export const SORT_TYPE = {
  ASC: 'ASC',
  DESC: 'DESC'
};

export const MIN_AUTO_COMPLETE_FILTER_CHAR = 2;
export const DEPLAY_AUTO_COMPLETE_FILTER_CHAR = 200;

export const CKEDITOR_CONFIG = {
  filebrowserUploadUrl: `https://api.rewardnetwork.vn/api/v1/media`,
  height: 600,
  debounce: 500
};

export const CAR_ATTRIBUTE_TYPE = {
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  YES_NO: 'YES_NO'
};

export const DISCOUNT_TYPE = {
  PERCENT: 'percent',
  AMOUNT: 'amount'
};

export const BOOKING_TIME_DEFAULT = {
  START_TIME: "21:00",
  END_TIME: "21:00",
};

export const DATETIMEPICKER_FORMAT = {
  YYYY_MM_DD: 'Y-m-d',
  MM_DD_YYYY: 'm-d-Y',
  DD_MMM_YY: 'd-M-y',
  DD_MMM_YY_H_i: 'd-M-y H:i',
  DD_MM_YYYY_H_i: 'M/d/yy h:mm a',
  DD_MM_YYYY: 'd-m-Y',
  YYYY_MM_DD_H_m: 'Y-m-d H:i',
  MM_DD_YYYY_H_m: 'm-d-Y H:m',
  DD_MM_YYYY_H_m: 'd-m-Y H:m',
  HH_MM_SSS: 'H:i',
  DD_MMM: 'd-M',
  MMM_YYYY: 'M-Y'
};

export const FIREBASE_DATABASE = {
  USERS: 'users',
  DEVICES: 'devices',
  INBOX: 'inbox',
};
export const AVATAR_DEFAULT = 'assets/img/avatar.png';

export const IMAGE_THUMBNAIL = {
  PRODUCT: {
    WIDTH: 80,
    HEIGHT: 120,
  },
  USER_AVATAR: {
    WIDTH: 100,
    HEIGHT: 100,
  },
  IMAGE_PRODUCT: {
    WIDTH: 157,
    HEIGHT: 237
  },
  BANNER: {
    WIDTH: 150,
    HEIGHT: 80,
  },
  POST: {
    WIDTH: 150,
    HEIGHT: 80,
  },
  NOTIFICATION: {
    WIDTH: 120,
    HEIGHT: 80,
  },
  LOGO: {
    WIDTH: 100,
    HEIGHT: 100,
  },
  APP_SLIDER: {
    WIDTH: 80,
    HEIGHT: 120,
  },
};

export const UPLOAD_IMAGE_TYPE = ['image/gif', 'image/jpeg', 'image/png'];
export const UPLOAD_FILE_TYPE = ['application/pdf', 'image/gif', 'image/jpeg', 'image/png'];
export const MAX_PRODUCT_IMAGE = 6;
export const MAX_NOTIFICATION_IMAGE = 1;
export const CROPPER_IMAGE = {
  OUT_PUT: 'jpeg',
  ASPECT_RATIO: {
    IMAGE_POST: 6 / 4,
    IMAGE_PRODUCT: 157 / 237,
    IMAGE_AVATAR: 1 / 1,
    IMAGE_LOGO: 1 / 1,
    IMAGE_BANNER: 961 / 368,
    IMAGE_PUSH_NOTIFICATION: 6 / 4,
    APP_SLIDER: 1125 / 2001,
  }
};
export const WAREHOUSE_PLANNING_TYPE = {
  IMPORT: 'Import',
  EXPORT: 'Export',
  SALE: 'Sale',
};

export const SETTING_KEYWORD = {
  POINT_CONFIG: "pointConfig",
  GENERAL_CONFIG: "generalConfig",
  PAYMENT_CONFIG: "paymentConfig",
};

export const PROMOTION_CODE_TYPE = {
  MONEY: "Money",
  PERCENTAGE: "Percentage",
};

export const PROMOTION_TYPE = {
  AVAILABLE: "available",
  RUNNING: "running",
  ENDED: "ended"
};

export const ORDER_STATUS = {
  PENDING: "Pending",
  CONFIRMED: "Confirmed",     // from admin/manager.
  DECLINED: "Declined",     // from admin/manager.
  COMPLETED: "Completed",
  CANCELLED: "Cancelled",     // Cancelled from customer or shipper.
};

export const POST_TYPE = {
  NEWS: "News",
  FASHION_GUIDE: "FashionGuide",
  PAGE: "Page",
};

export const PAYMENT_METHOD = {
  COD: "COD",
  INTERNAL_CARD: "InternalCard",
  VISA_CARD: "VisaCard",
};

export const NOTIFICATION_TYPE = {
  ORDER: 'order',
  TEXT: 'text',
  LINK: 'link',
  SUGGESTION: 'suggestion',
  PROMOTION: 'promotion',
  PRODUCT: 'product'
};

export const PROMOTION_CODE = {
  TYPE: {
    PERCENTAGE: 'Percentage',
    MONEY: 'Money',
    SERVICE_FEE: 'ServiceFee',
  },
  TARGET: {
    FOR_ALL_USER: 'ForAllUser',
    FOR_USER_NOT_ORDER: 'ForUserNotOrder',
    FOR_VALUE_OF_USER: 'ForValueOfUser'
  }
};


export const MONTH_FILTER = {
  THIS_MONTH: 'this_month',
  LAST_MONTH: 'last_month'
};

export const YEAR_FILTER = {
  THIS_YEAR: 'this_year',
  LAST_YEAR: 'last_last'
};

export const DATE_FORMAT = {
  DD_MM_YYYY: 'dd-MM-yyyy',
  H_MM_A_DD_MM_YYYY: 'h:mm a dd-MM-yyyy',
  DD_MM_YYYY_H_MM: 'dd-MM-yyyy h:mm',
};


export const REPORT_GROUP_BY = {
  ALL: 'all',
  MONTH_YEAR: 'month_year',
  SHOP: 'shop',
  USER: 'user',
  NEW_USER: 'new_user',
  PRODUCT: 'product',
  CATEGORY: 'category',
  COUNTRY: 'country',
  CITY: 'city',
  DISTRICT: 'district',
  ZONE: 'zone'
};
export const DEFAULT_SHOP_ID = "fc4a9b92-9e32-498c-897a-c393d8b8e19b";

export const PAYMENT_SOURCE = {
  ONEPAY: "OnePay"
};

export const PUBLIC_KEY = '-----BEGIN PUBLIC KEY-----\n MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAo1+RX/UsJFhnqG/VAsRp\n vuPh/YSrnNYx2bRbkumWaexjWk6tdi8pzaceFdY6jn8rl5kD7GGZrnF8KooLSKq2\n 2wm7tW9PFocev7mXeB06mNgwmynCcCnr+ghfr+UzyKsa5HblYxrICUzAhctymas7\n A1Uc/48YAI7qO6fdNvWtPCqUuDZ2sz32XV7cHI+uVdLK08thfzuU5MX/mT4X6f1b\n l/TutDf/Jdj9t3h3K/GbQr7sWRfW0+pHqIfrnvweDyCXnFtPaZcN/FVpUZTN97RT\n E18OdnbWFyMY27NK6aDoTezkNuLq8/ZkH/KW9cga8QruRMFRdMjgkqbCbRRhMObU\n bQIDAQAB\n-----END PUBLIC KEY-----\n';

export const PLATFORM_VERSION = ['iOS', 'Android'];