import { JsonMapper } from '../modules/mapper/json.mapper';
import { ResponseModel, PaginationModel, OrderModel } from '../models';
import { UtilHelper } from '../helpers/util.helper';
import { TransferHttp } from '../modules/transfer-http/transfer-http';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { REST_API } from '../modules/constants';

@Injectable()
export class OrderService extends BaseService {
  constructor(public http: TransferHttp) {
    super(http);
  }

  /**
   * Get list Order
   * @returns {Promise<T>|Promise<U>}
   */
  public findAll(paging: boolean = true, filter: any = {}): Promise<PaginationModel> {

    if (!paging) {
      filter.offset = '';
      filter.limit = '';
    }

    const queryString = UtilHelper.parseFilterToString(filter);
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.ORDER + '?' + queryString)
      .then((res) => {

        res.data = JsonMapper.deserialize(OrderModel, res.data);
        return res;
      });
  }


  /**
   * Get Order detail
   * @returns {Promise<T>|Promise<U>}
   */
  public findById(id, filter: any = {}): Promise<OrderModel> {
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.ORDER + '/' + id)
      .then((res) => {
        return JsonMapper.deserialize(OrderModel, res);
      });
  }


  /**
   * create Order
   * @returns {Promise<T>|Promise<U>}
   */
  public create(data: OrderModel): Promise<ResponseModel> {
    return this.makeHttpPost(`${this.apiUrl}/` + REST_API.ORDER + '/admin/create', JsonMapper.serialize(data));
  }

  public checkout(data: OrderModel): Promise<OrderModel> {
    return this.makeHttpPost(`${this.apiUrl}/` + REST_API.ORDER + '/admin/checkout', JsonMapper.serialize(data));
  }

  /**
   * edit Order
   * @returns {Promise<T>|Promise<U>}
   */
  public update(data: OrderModel): Promise<ResponseModel> {
    return this.makeHttpPut(`${this.apiUrl}/` + REST_API.ORDER + '/' + data.id, JsonMapper.serialize(data));
  }

  public changeStatus(id: string, status: string): Promise<ResponseModel> {
    return this.makeHttpPut(`${this.apiUrl}/` + REST_API.ORDER + '/status/' + id, { status: status });
  }

  public print(ids: string[]): Promise<string> {
    return this.makeHttpPost(`${this.apiUrl}/` + REST_API.ORDER + '/print', ids);
  }

  /**
   * delete Order
   * @returns {Promise<T>|Promise<U>}
   */
  public delete(id: string): Promise<ResponseModel> {
    return this.makeHttpDelete(`${this.apiUrl}/` + REST_API.ORDER + '/' + id);
  }
}
