import { BaseModel } from './base.model';
import { ValidateModel } from './validate.model';
import { Json } from '../modules';
import { MediaModel } from './media.model';
import { ProductSizeModel } from './product_size.model';

export class ProductModel extends BaseModel {

  @Json('name')
  public name: string = undefined;

  @Json('sku')
  public sku: string = undefined;

  @Json('description')
  public description: string = undefined;

  @Json('colorName')
  public colorName: string = undefined;

  @Json('colorCode')
  public colorCode: string = undefined;

  @Json('categoryId')
  public categoryId: string = undefined;

  @Json('image')
  public image: string = undefined;

  @Json('size')
  public size: string = undefined;

  @Json('quantity')
  public quantity: number = undefined;
  
  @Json('totalSale')
  public totalSale: number = undefined;

  @Json('isEnable')
  public isEnable: boolean = undefined;

  @Json('isOutOfStock')
  public isOutOfStock: boolean = undefined;

  @Json('isDiscount')
  public isDiscount: boolean = undefined;

  @Json({ name: 'images', clazz: MediaModel})
  public images: MediaModel[] = undefined;

  @Json({ name: 'productSizes'})
  public productSizes: any[] = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  /**
   * init validate rule
   * @returns {ValidateModel}
   */
  public initValidateRules(): ValidateModel {
    this.addRule('name', 'required', true, this._t('Tên sản phẩm không được bỏ trống.'));
    this.addRule('sku', 'required', true, this._t('Mã sản phẩm không được bỏ trống.'));
    this.addRule('categoryId', 'required', true, this._t('Danh mục không được bỏ trống.'));
    this.addRule('colorCode', 'required', true, this._t('Màu sắc không được bỏ trống.'));

    return this.getRules();
  }
}