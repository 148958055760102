import { BaseModel } from "./base.model";
import { Json } from "app/modules";
import { SizeModel } from "./size.model";
import { ProductModel } from "./product.model";

export class ProductSizeModel extends BaseModel {
    @Json("productId")
    public productId: string = undefined;

    @Json("sizeId")
    public sizeId: string = undefined;

    @Json("quantity")
    public quantity: number = 0;

    @Json("price")
    public price: number = 0;

    @Json("discountPrice")
    public discountPrice: number = 0;

    @Json("discountPercentage")
    public discountPercentage: number = 0;

    @Json("height")
    public height: number = undefined;

    @Json("weight")
    public weight: number = undefined;

    @Json("waist")
    public waist: number = undefined;

    @Json("chest")
    public chest: number = undefined;

    @Json("hip")
    public hip: number = undefined;

    @Json('isOutOfStock')
    public isOutOfStock: boolean = undefined;

    @Json('isDiscount')
    public isDiscount: boolean = undefined;

    @Json({ name: 'size', clazz: SizeModel })
    public size: SizeModel = undefined;

    @Json({ name: 'product', clazz: ProductModel })
    public product: ProductModel = undefined;
}