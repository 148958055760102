import { BaseModel } from './base.model';
import { ValidateModel } from './validate.model';
import { Json } from '../modules';
import { UserModel } from './user.model';
import { CountryModel } from './country.model';
import { CityModel } from './city.model';
import { WardModel } from './ward.model';
import { DistrictModel } from './district.model';

export class AddressBookModel extends BaseModel {
  @Json("userId")
  public userId: string = undefined;

  @Json("name")
  public name: string = undefined;

  @Json("phoneNumber")
  public phoneNumber: string = undefined;

  @Json("address")
  public address: string = undefined;

  @Json("fullAddress")
  public fullAddress: string = undefined;

  @Json("latitude")
  public latitude: string = undefined;

  @Json("longitude")
  public longitude: string = undefined;

  @Json("countryId")
  public countryId: string = undefined;

  @Json("cityId")
  public cityId: string = undefined;

  @Json("districtId")
  public districtId: string = undefined;

  @Json("wardId")
  public wardId: string = undefined;

  @Json("country")
  public country: CountryModel = undefined;

  @Json("city")
  public city: CityModel = undefined;

  @Json("district")
  public district: DistrictModel = undefined;

  @Json("ward")
  public ward: WardModel = undefined;

  @Json("isDefault")
  public isDefault: boolean = undefined;

  @Json("isEnable")
  public isEnable: boolean = undefined;

  @Json({ name: "user", clazz: UserModel, omitEmpty: true })
  public user: UserModel = undefined;


  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  /**
   * init validate rule
   * @returns {ValidateModel}
   */
  public initValidateRules(): ValidateModel {

    return this.getRules();
  }
}