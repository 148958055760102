import { JsonMapper } from '../modules/mapper/json.mapper';
import { ResponseModel, PaginationModel, WarehousePlanningModel } from '../models';
import { UtilHelper } from '../helpers/util.helper';
import { TransferHttp } from '../modules/transfer-http/transfer-http';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { REST_API } from '../modules/constants';

@Injectable()
export class WarehouseService extends BaseService {
  constructor(public http: TransferHttp) {
    super(http);
  }

  /**
   * Get list Point
   * @returns {Promise<T>|Promise<U>}
   */
  public findAll(paging: boolean = true, filter: any = {}): Promise<PaginationModel> {

    if (!paging) {
      filter.offset = '';
      filter.limit = '';
    }

    const queryString = UtilHelper.parseFilterToString(filter);
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.WAREHOUSE_PLANNING + '?' + queryString)
      .then((res) => {

        res.data = JsonMapper.deserialize(WarehousePlanningModel, res.data);
        return res;
      });
  }

  /**
   * Get Point detail
   * @returns {Promise<T>|Promise<U>}
   */
  public findById(id, filter: any = {}): Promise<WarehousePlanningModel> {
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.WAREHOUSE_PLANNING + '/' + id)
      .then((res) => {
        return JsonMapper.deserialize(WarehousePlanningModel, res);
      });
  }


  /**
   * create Point
   * @returns {Promise<T>|Promise<U>}
   */
  public create(data: WarehousePlanningModel): Promise<ResponseModel> {
    return this.makeHttpPost(`${this.apiUrl}/` + REST_API.WAREHOUSE_PLANNING , JsonMapper.serialize(data));
  }

  /**
   * edit Point
   * @returns {Promise<T>|Promise<U>}
   */
  public update(data: WarehousePlanningModel): Promise<ResponseModel> {
    return this.makeHttpPut(`${this.apiUrl}/` + REST_API.WAREHOUSE_PLANNING + '/' + data.id, JsonMapper.serialize(data));
  }

  /**
   * delete Point
   * @returns {Promise<T>|Promise<U>}
   */
  public delete(id: string): Promise<ResponseModel> {
    return this.makeHttpDelete(`${this.apiUrl}/` + REST_API.WAREHOUSE_PLANNING + '/' + id);
  }
}
