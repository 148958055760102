import { BaseModel } from './base.model';
import { ValidateModel } from './validate.model';
import { Json } from '../modules';
import { UserModel } from './user.model';
import { MediaModel } from './media.model';
import { OrderItemModel } from './order_item.model';
import { AddressBookModel } from './address_book.model';

export class OrderModel extends BaseModel {
  @Json("userId")
  public userId: string = undefined;

  @Json("qrCodeId")
  public qrCodeId: string = undefined;

  @Json("code")
  public code: string = undefined;

  @Json("totalPrice")
  public totalPrice: number = undefined;

  @Json("totalItem")
  public totalItem: number = undefined;

  @Json("shippingFee")
  public shippingFee: number = undefined;

  @Json({ name: "shippingDate" })
  public shippingDate: string = undefined;

  @Json("status")
  public status: string = undefined;

  @Json("addressBookId")
  public addressBookId: string = undefined;

  @Json("paymentMethod")
  public paymentMethod: string = undefined;

  @Json("isPaid")
  public isPaid: boolean = undefined;

  @Json("note")
  public note: string = undefined;

  @Json({ name: "shippingInfo" })
  public shippingInfo: AddressBookModel = undefined;

  @Json({ name: "promotionInfo" })
  public promotionInfo: any = undefined;

  @Json("subTotal")
  public subTotal: number = undefined;

  @Json({ name: "qrCode", clazz: MediaModel, omitEmpty: true })
  public qrCode: MediaModel = undefined;

  @Json("step")
  public step: string = undefined;

  @Json("createdBy")
  public createdBy: string = undefined;

  @Json("discount")
  public discount: number = undefined;

  @Json("promotionCode")
  public promotionCode: string = undefined;

  @Json("pointMoney")
  public pointMoney: number = undefined;

  @Json("pointAmount")
  public pointAmount: number = undefined;

  @Json({ name: "user", clazz: UserModel, omitEmpty: true })
  public user: UserModel = undefined;

  @Json({ name: "orderItems", clazz: OrderItemModel, omitEmpty: true })
  public orderItems: OrderItemModel[] = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  /**
   * init validate rule
   * @returns {ValidateModel}
   */
  public initValidateRules(): ValidateModel {
    this.addRule('customerName', 'required', true, this._t('Tên khách hàng không được bỏ trống.'));
    this.addRule('customerPhone', 'required', true, this._t('Số điện thoại không được bỏ trống.'));
    this.addRule('shippingName', 'required', true, this._t('Tên người nhận không được bỏ trống.'));
    this.addRule('shippingPhone', 'required', true, this._t('Số điện thoại không được bỏ trống.'));
    this.addRule('shippingAddress', 'required', true, this._t('Địa chỉ không được bỏ trống.'));
    return this.getRules();
  }
}