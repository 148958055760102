import { RouteInfo } from './sidebar.metadata';
import { ROLE } from 'app/modules';

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
    {
        path: '/dashboard',
        title: 'Dashboard',
        icon: 'ft-home',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER, ROLE.MERCHANT]
    },
    {
        path: '/orders',
        title: 'Đơn hàng',
        icon: 'ft-shopping-cart',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER, ROLE.MERCHANT, ROLE.OPERATOR]
    },
    {
        path: '#',
        title: 'Sản phẩm',
        icon: 'ft-box',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER, ROLE.MERCHANT, ROLE.OPERATOR],
        submenu: [
            {
                path: '/categories',
                title: 'Danh mục',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER, ROLE.MERCHANT],
                submenu: [],
            },
            {
                path: '/sizes',
                title: 'Kích thước',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER, ROLE.MERCHANT]
            },
            {
                path: '/colors',
                title: 'Màu sắc',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER, ROLE.MERCHANT]
            },
            {
                path: '/products',
                title: 'Sản phẩm',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER, ROLE.MERCHANT, ROLE.OPERATOR]
            },
        ],
    },
    {
        path: '/warehouses',
        title: 'Quản lý kho',
        icon: 'ft-briefcase',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER, ROLE.MERCHANT, ROLE.OPERATOR]
    },
    {
        path: '/promotions',
        title: 'Chương trình giảm giá',
        icon: 'ft-copy',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER, ROLE.MERCHANT]
    },
    {
        path: '/promotion_codes',
        title: 'Mã khuyến mãi',
        icon: 'ft-tag',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER, ROLE.MERCHANT]
    },
    {
        path: '/notification',
        title: 'Thông báo',
        icon: 'ft-bell',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER, ROLE.MERCHANT]
    },
    {
        path: '/banners',
        title: 'Banner',
        icon: 'ft-image',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER, ROLE.MERCHANT]
    },
    {
        path: '#',
        title: 'Bài viết',
        icon: 'ft-file-text',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER, ROLE.MERCHANT, ROLE.CONTENT],
        submenu: [
            // {
            //     path: '/post-categories',
            //     title: 'Danh mục',
            //     icon: '',
            //     class: '',
            //     badge: '',
            //     badgeClass: '',
            //     isExternalLink: false,
            //     privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER, ROLE.MERCHANT],
            //     submenu: [],
            // },
            {
                path: '/news',
                title: 'News',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER, ROLE.MERCHANT, ROLE.CONTENT],
                submenu: [],
            },
            {
                path: '/fashion-guides',
                title: 'Fashion Guides',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER, ROLE.MERCHANT, ROLE.CONTENT],
                submenu: [],
            },
            {
                path: '/pages',
                title: 'Pages',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER, ROLE.MERCHANT],
                submenu: [],
            },
        ]
    },
    {
        path: '#',
        title: 'Địa điểm',
        icon: 'ft-map-pin',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER],
        submenu: [
            {
                path: '/location/cities',
                title: 'Tỉnh/Thành phố',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER],
                submenu: [],
            },
            {
                path: '/location/districts',
                title: 'Quận/huyện',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER],
                submenu: [],
            },
            {
                path: '/location/wards',
                title: 'Phường/xã',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER],
                submenu: [],
            },
        ]
    },
    {
        path: '/points',
        title: 'Tích điểm',
        icon: 'ft-award',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER, ROLE.MERCHANT, ROLE.STORE_SUPPORT]
    },
    {
        path: '/point_types',
        title: 'Thẻ thành viên',
        icon: 'ft-layers',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER]
    },
   
    {
        path: '/users',
        title: 'Thành viên',
        icon: 'ft-users',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER, ROLE.MERCHANT]
    },
    {
        path: '/settings',
        title: 'Cài đặt',
        icon: 'ft-settings',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER, ROLE.MERCHANT]
    },
    {
        path: '/app_versions',
        title: 'App version',
        icon: 'ft-codepen',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER]
    },
];
