import { BaseModel } from './base.model';
import { ValidateModel } from './validate.model';
import { Json } from '../modules';
import { UserModel } from './user.model';

export class UserPointModel extends BaseModel {

  @Json("userId")
  public userId: string = undefined;

  @Json("point")
  public point: string = undefined;

  @Json("orderId")
  public orderId: string = undefined;

  @Json("note")
  public note: string = undefined;

  @Json("isIncrease")
  public isIncrease: boolean = undefined;

  @Json("externalOrderId")
  public externalOrderId: string = undefined;

  @Json("totalAmount")
  public totalAmount: number = undefined;

  @Json({ name: "user", clazz: UserModel, omitEmpty: true })
  public user: UserModel = undefined;
  
  @Json({ name: "creator", clazz: UserModel, omitEmpty: true })
  public creator: UserModel = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  /**
   * init validate rule
   * @returns {ValidateModel}
   */
  public initValidateRules(): ValidateModel {
    this.addRule('name', 'required', true, this._t('Tên khách hàng không được bỏ trống.'));
    this.addRule('phoneNumber', 'required', true, this._t('Số điện thoại được bỏ trống.'));
    this.addRule('externalOrderId', 'required', true, this._t('Mã hoá đơn không được bỏ trống.'));
    this.addRule('isIncrease', 'required', true, this._t('Loại không được bỏ trống.'));
    this.addRule('totalAmount', 'required', true, this._t('Tổng tiền không được bỏ trống.'));
    this.addRule('totalAmount', 'numeric', true, this._t('Tổng tiền phải là số.'));

    return this.getRules();
  }
}