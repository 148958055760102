import { BaseModel } from "./base.model";
import { Json } from "app/modules";
import { ValidateModel } from "./validate.model";
import { MediaModel } from './media.model';

export class GeneralConfigModel extends BaseModel {
    @Json("shopName")
    public shopName: string = undefined;

    @Json("websiteLogo")
    public websiteLogo: MediaModel = undefined;

    @Json("appLogo")
    public appLogo: MediaModel = undefined;

    @Json("appImages")
    public appImages: MediaModel[] = undefined;

    @Json("website")
    public website: string = undefined;

    @Json("address")
    public address: string = undefined;

    @Json("phoneNumber")
    public phoneNumber: string = undefined;

    @Json("email")
    public email: string = undefined;

    @Json("minOrderAmount")
    public minOrderAmount: number = undefined;

    constructor() {
        super();
        this.validateRules = new ValidateModel();
        this.initValidateRules();
    }

    public initValidateRules(): ValidateModel {
        this.addRule('minOrderAmount', 'required', true, this._t('Hoá đơn tối thiểu không được để trống.'));

        return this.getRules();
    }
}
