import { BaseModel } from './base.model';
import { ValidateModel } from './validate.model';
import { Json } from '../modules';
import { UserModel } from './user.model';
import { MediaModel } from './media.model';

export class PromotionCodeModel extends BaseModel {
  @Json("title")
  public title: string = undefined;

  @Json("shortDescription")
  public shortDescription: string = undefined;

  @Json("description")
  public description: string = "";

  @Json("imageId")
  public imageId: string = undefined;

  @Json("promotionCode")
  public promotionCode: string = undefined;

  @Json("promotionValue")
  public promotionValue: number = undefined;

  @Json("type")
  public type: string = undefined;

  @Json("isEnable")
  public isEnable: boolean = undefined;

  @Json("createdBy")
  public createdBy: string = undefined;

  @Json({ name: "validFrom" })
  public validFrom: string = undefined;

  @Json({ name: "validTo" })
  public validTo: string = undefined;

  @Json("minQuantity")
  public minQuantity: number = undefined;

  @Json("minPrice")
  public minPrice: number = undefined;

  @Json("maxDiscount")
  public maxDiscount: number = undefined;

  @Json("maxUser")
  public maxUser: number = undefined;

  @Json("timesForUse")
  public timesForUse: number = undefined;

  @Json({ name: "creator", clazz: UserModel, omitEmpty: true })
  public creator: UserModel = undefined;

  @Json({ name: "image", clazz: MediaModel, omitEmpty: true })
  public image: MediaModel = undefined;

  @Json("needPush")
  public needPush: boolean = undefined;

  @Json("isAvailable")
  public isAvailable: boolean = undefined;

  @Json("isRunning")
  public isRunning: boolean = undefined;

  @Json("isEnded")
  public isEnded: boolean = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  /**
   * init validate rule
   * @returns {ValidateModel}
   */
  public initValidateRules(): ValidateModel {
    this.addRule('title', 'required', true, this._t('Tên khuyến mãi không được bỏ trống.'));
    this.addRule('shortDescription', 'required', true, this._t('Mô tả ngắn không được bỏ trống.'));
    this.addRule('type', 'required', true, this._t('Loại khuyến mãi không được bỏ trống.'));
    this.addRule('promotionCode', 'required', true, this._t('Mã khuyến mãi không được bỏ trống.'));
    this.addRule('promotionValue', 'required', true, this._t('Giá trị khuyến mãi không được bỏ trống.'));
    return this.getRules();
  }
}