/**
 * Created by phuongho on 7/17/17.
 */
export * from './base.service';
export * from './user_identity.service';
export * from './auth.service';
export * from './user.service';
export * from './auth_guard.service';
export * from './role.service';
export * from './setting.service';
export * from './media.service';
export * from './firebase.service';
export * from './category.service';
export * from './color.service';
export * from './size.service';
export * from './product.service';
export * from './point_type.service';
export * from './point.service';
export * from './warehouse.service';
export * from './banner.service';
export * from './promotion_code.service';
export * from './promotion.service';
export * from './order.service';
export * from './address_book.service';
export * from './post.service';
export * from './post_category.service';
export * from './location.service';
export * from './notification.service';
export * from './report.service';
export * from './app_version.service';