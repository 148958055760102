import { SESSION, LANGUAGE, PAYMENT_METHOD, PUBLIC_KEY } from '../modules/constants';
import * as _ from 'lodash';
import * as stringFormat from 'string-format';

export class UtilHelper {
  constructor() {

  }

  /**
   * Set Default Data
   * @param data
   * @returns {string}
   */
  static setDataDefault(data, defaultValue: any = '') {
    const ret = data ? data : ((defaultValue !== '') ? defaultValue : '');

    return ret;
  }

  /**
   * Parse
   * @param data
   * @returns {string}
   */
  public static parseFilterToString(data) {
    let str = '';
    if (data) {
      _.forEach(data, function (item, key) {
        if (typeof item !== 'object') {
          str += key + '=' + item + '&';
        }
      });
    }

    return str.substring(0, str.length - 1);
  }

  /**
   * Parse
   * @param data
   * @returns {string}
   */
  public static parseFilterToStringNoEmpty(data) {
    let str = '';
    if (data) {
      _.forEach(data, function (item, key) {
        if (typeof item !== 'object' && item !== '') {
          str += key + '=' + item + '&';
        }
      });
    }

    return str.substring(0, str.length - 1);
  }

  /**
   * Parse
   * @param string
   * @returns {object}
   */
  public static parseQueryStringToObject(string) {
    const vars = string.split('&');
    const result = {};
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      result[pair[0]] = pair[1];
    }
    return result;
  }

  /**
   * Parse
   * @param data
   * @returns {string}
   */
  public static parseArrayToString(data) {
    let str = '';
    if (data) {
      _.forEach(data, function (item, key) {
        if (typeof item !== 'object') {
          str += item + ',';
        }
      });
    }

    return str.substring(0, str.length - 1);
  }

  /**
   *
   * @param array
   * @param attribute
   * @param value
   * @returns {any}
   */
  public static findByAttribute(array, attribute, value) {
    return _.find(array, function (item) {
      return item[attribute] === value;
    });
  }

  /**
   *
   * @param array
   * @param attribute
   * @param value
   * @returns {Array}
   */
  public static findAllByAttribute(array, attribute, value) {
    const result = [];
    _.forEach(array, function (item) {
      if (item[attribute] === value) {
        result.push(item);
      }
    });

    return result;
  }

  /**
   *
   * @param message
   * @param params
   */
  public static translate(message: any, params: any[] = []): string {
    let language: any = sessionStorage.getItem(SESSION.LANGUAGE_KEYWORD);

    let ret: string = message.toString();

    let data = require('../../assets/i18n/vi.json');
    if (language) {
      language = JSON.parse(language);
      if (language.code !== LANGUAGE.VIETNAM) {
        data = require('../../assets/i18n/' + language.code + '.json');
      }
    }
    if (data[message]) {
      ret = data[message];
    }

    if (_.isArray(params)) {
      ret = stringFormat(ret, ...params);
    } else if (_.isString(ret) || _.isObject(params)) {
      ret = stringFormat(ret, params);
    }
    return ret;
  }



  /**
   * Use to slice long string
   * @param desc
   * @returns {string}
   */
  public static sliceText(text: string, maxLength: number) {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }

    return text;
  }

  static setZeroNumber(data) {
    const ret = (!data) ? '' : data;
    return ret;
  }

  static getPaymentMethodName(type: string) {
    let result: string = "";
    switch (type) {
      case PAYMENT_METHOD.COD:
        result = "COD";
        break;
      case PAYMENT_METHOD.INTERNAL_CARD:
        result = "Thẻ nội địa";
        break;
      case PAYMENT_METHOD.VISA_CARD:
        result = "Thẻ quốc tế";
        break;
    }

    return result;
  }

  public static encryptRSA(toEncrypt, publicKey: string = PUBLIC_KEY) {
      let encrypt = new JSEncrypt();
      encrypt.setPublicKey(publicKey);
      return encrypt.encrypt(toEncrypt);
  }

  static getBoolean(val, defaultVal = false) {
    if (val == null) {
        return defaultVal;
    }
    switch (this.getString(val).toLowerCase()) {
        case "true":
        case "1":
        case "on":
        case "yes":
        case "y":
            return true;
        case "false":
        case "0":
        case "off":
        case "no":
        case "n":
            return false;
        default:
            return defaultVal;
    }
}
static getDateFromString(input, defaultVal = new Date()) {
    let ret = defaultVal;
    if (input != null) {
        ret = new Date(input);
    }
    return ret;
}

static getInteger(input, defaultVal = 0, radix = 10) {
    let ret = defaultVal;
    if (input != null) {
        const parsed = Number.parseInt(input, radix);
        if (!Number.isNaN(parsed)) {
            ret = parsed;
        }
    }
    return ret;
}

static getFloat(input, defaultVal = 0.0) {
    let ret = defaultVal;
    if (input != null) {
        const parsed = Number.parseFloat(input);
        if (!Number.isNaN(parsed)) {
            ret = parsed;
        }
    }
    return ret;
}

static getString(input, defaultVal = undefined) {
    let ret = defaultVal;
    if (input != null) {
        ret = input.toString();
    }
    return ret;
}

static stringToInt(input, defaultVal = 0, radix = 10) {
    let ret = defaultVal;
    if (input != null) {
        const parsed = Number.parseInt(input, radix);
        if (!Number.isNaN(parsed)) {
            ret = parsed;
        }
    }
    return ret;
}

static stringToFloat(input, defaultVal = 0.0) {
    let ret = defaultVal;
    if (input != null) {
        const parsed = Number.parseFloat(input);
        if (!Number.isNaN(parsed)) {
            ret = parsed;
        }
    }
    return ret;
}
}
