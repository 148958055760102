import { BaseModel } from './base.model';
import * as moment from 'moment';
import { Json } from '../modules';
import { CountryModel } from './country.model';
import { CityModel } from './city.model';
import { DistrictModel } from './district.model';

export class ReportOrderLocationModel extends BaseModel {
  @Json("reportDate")
  public reportDate: string = undefined;

  @Json("reportHour")
  public reportHour: number = undefined;

  @Json("reportMonth")
  public reportMonth: number = undefined;

  @Json("reportYear")
  public reportYear: number = undefined;

  @Json("countryId")
  public countryId: string = undefined;

  @Json({ name: "country", clazz: CountryModel, omitEmpty: true })
  public country: CountryModel = undefined;

  @Json("cityId")
  public cityId: string = undefined;

  @Json({ name: "city", clazz: CityModel, omitEmpty: true })
  public city: CityModel = undefined;

  @Json("districtId")
  public districtId: string = undefined;

  @Json({ name: "district", clazz: DistrictModel, omitEmpty: true })
  public district: DistrictModel = undefined;

  @Json("zoneId")
  public zoneId: string = undefined;

  @Json("totalAmount")
  public totalAmount: number = undefined;

  @Json("totalPrice")
  public totalPrice: number = undefined;

  @Json("totalAmountBuy")
  public totalAmountBuy: number = undefined;

  @Json("totalPriceBuy")
  public totalPriceBuy: number = undefined;

  @Json("totalAmountTry")
  public totalAmountTry: number = undefined;

  @Json("totalPriceTry")
  public totalPriceTry: number = undefined;
}
