import { Routes } from '@angular/router';

// Route for content layout with sidebar, navbar and footer.
export const FULL_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: 'app/pages/dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'users',
    loadChildren: 'app/pages/users/user.module#UserModule'
  },
  {
    path: 'settings',
    loadChildren: 'app/pages/settings/setting.module#SettingModule'
  },
  {
    path: 'account',
    loadChildren: 'app/pages/account/account.module#AccountModule'
  },
  {
    path: 'categories',
    loadChildren: 'app/pages/category/category.module#CategoryModule'
  },
  {
    path: 'sizes',
    loadChildren: 'app/pages/product_sizes/sizes.module#SizeModule'
  },
  {
    path: 'colors',
    loadChildren: 'app/pages/color/color.module#ColorModule'
  },
  {
    path: 'products',
    loadChildren: 'app/pages/products/product.module#ProductModule'
  },
  {
    path: 'point_types',
    loadChildren: 'app/pages/point_types/point_type.module#PointTypeModule'
  },
  {
    path: 'points',
    loadChildren: 'app/pages/points/point.module#PointModule'
  },
  {
    path: 'warehouses',
    loadChildren: 'app/pages/warehouses/warehouse.module#WarehouseModule'
  },
  {
    path: 'banners',
    loadChildren: 'app/pages/banner/banner.module#BannerModule'
  },
  {
    path: 'promotion_codes',
    loadChildren: 'app/pages/promotion_codes/promotion_code.module#PromotionCodeModule'
  },
  {
    path: 'promotions',
    loadChildren: 'app/pages/promotions/promotion.module#PromotionModule'
  },
  {
    path: 'orders',
    loadChildren: 'app/pages/orders/order.module#OrderModule'
  },
  {
    path: 'news',
    loadChildren: 'app/pages/news/post.module#PostModule'
  },
  {
    path: 'pages',
    loadChildren: 'app/pages/pages/post.module#PostModule'
  },
  {
    path: 'fashion-guides',
    loadChildren: 'app/pages/fashion_guide/post.module#PostModule'
  },
  {
    path: 'post-categories',
    loadChildren: 'app/pages/post_category/post_category.module#PostCategoryModule'
  },
  {
    path: 'location',
    loadChildren: 'app/pages/location/location.module#LocationModule'
  },
  {
    path: 'notification',
    loadChildren: 'app/pages/notification/notification.module#NotificationModule'
  },
  {
    path: 'app_versions',
    loadChildren: 'app/pages/app_version/app_version.module#AppVersionModule'
  },
];