/**
 * Created by phuongho on 10/16/16.
 */

import { BaseSearchModel } from "./base.search.model";

export class SearchReportModel extends BaseSearchModel {
    public shopId: string = '';
    public cityId: string = '';
    public startDate: string = '';
    public endDate: string = '';
    public month: number;
    public year: number;
    public groupBy: string = '';
}
