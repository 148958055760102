import { BaseModel } from './base.model';
import { ValidateModel } from './validate.model';
import { Json } from '../modules';
import { ProductSizeModel } from './product_size.model';

export class OrderItemModel extends BaseModel {
  @Json("orderId")
  public orderId: string = undefined;

  @Json("productSizeId")
  public productSizeId: string = undefined;

  @Json({ name: "productSize", clazz: ProductSizeModel, omitEmpty: true })
  public productSize: ProductSizeModel = undefined;

  @Json("name")
  public name: string = undefined;

  @Json("image")
  public image: string = undefined;

  @Json("size")
  public size: string = undefined;

  @Json("colorCode")
  public colorCode: string = undefined;

  @Json("colorName")
  public colorName: string = undefined;

  @Json("price")
  public price: number = undefined;

  @Json("quantity")
  public quantity: number = undefined;

  @Json("maxQuantity")
  public maxQuantity: number = undefined;     // Support for re-calculate the order.

  @Json("subTotal")
  public subTotal: number = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  /**
   * init validate rule
   * @returns {ValidateModel}
   */
  public initValidateRules(): ValidateModel {
    return this.getRules();
  }
}