import { BaseModel } from './base.model';
import { ValidateModel } from './validate.model';
import { Json } from '../modules';
import { UserModel } from './user.model';
import { MediaModel } from './media.model';
import { ProductSizeModel } from './product_size.model';

export class PromotionItemModel extends BaseModel {
  @Json("isDeleted")
  public isDeleted: boolean = undefined;

  @Json("promotionId")
  public promotionId: string = undefined;

  @Json("productSizeId")
  public productSizeId: string = undefined;

  @Json("discountPrice")
  public discountPrice: number = undefined;

  @Json("discountPercentage")
  public discountPercentage: number = undefined;

  @Json({ name: "productSize", clazz: ProductSizeModel, omitEmpty: true, ignore: true })
  public productSize: ProductSizeModel = undefined;


  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  /**
   * init validate rule
   * @returns {ValidateModel}
   */
  public initValidateRules(): ValidateModel {
    return this.getRules();
  }
}