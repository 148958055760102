import { JsonMapper } from '../modules/mapper/json.mapper';
import { ResponseModel, PaginationModel, CategoryModel, TokenModel, CityModel, WardModel, DistrictModel } from '../models';
import { UtilHelper } from '../helpers/util.helper';
import { TransferHttp } from '../modules/transfer-http/transfer-http';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { REST_API } from '../modules/constants';

@Injectable()
export class LocationService extends BaseService {
  constructor(public http: TransferHttp) {
    super(http);
  }

  /**
   * Get list city
   * @returns {Promise<T>|Promise<U>}
   */
  public findAllCity(paging: boolean = true, filter: any = {}): Promise<PaginationModel> {

    if (!paging) {
      filter.offset = '';
      filter.limit = '';
    }

    const queryString = UtilHelper.parseFilterToString(filter);
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.LOCATION.CITY + '?' + queryString)
      .then((res) => {

        res.data = JsonMapper.deserialize(CityModel, res.data);
        return res;
      });
  }

  /**
   * Get list city
   * @returns {Promise<T>|Promise<U>}
   */
  public findAllDistrict(paging: boolean = true, filter: any = {}): Promise<PaginationModel> {

    if (!paging) {
      filter.offset = '';
      filter.limit = '';
    }

    const queryString = UtilHelper.parseFilterToString(filter);
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.LOCATION.DISTRICT + '?' + queryString)
      .then((res) => {

        res.data = JsonMapper.deserialize(DistrictModel, res.data);
        return res;
      });
  }

  /**
   * Get list ward
   * @returns {Promise<T>|Promise<U>}
   */
  public findAllWard(paging: boolean = true, filter: any = {}): Promise<PaginationModel> {

    if (!paging) {
      filter.offset = '';
      filter.limit = '';
    }

    const queryString = UtilHelper.parseFilterToString(filter);
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.LOCATION.WARD + '?' + queryString)
      .then((res) => {

        res.data = JsonMapper.deserialize(WardModel, res.data);
        return res;
      });
  }


  /**
   * edit Category
   * @returns {Promise<T>|Promise<U>}
   */
  public updateDistrict(data: DistrictModel): Promise<ResponseModel> {
    return this.makeHttpPut(`${this.apiUrl}/` + REST_API.LOCATION.DISTRICT + '/' + data.id, JsonMapper.serialize(data));
  }
}