export const setting = {
    rest: {
        // apiUrl: "http://localhost:3000/api/v1",
        // apiUrl: "https://sandbox-api.rewardnetwork.vn/api/v1",
        // mediaUrl: "https://sandbox-cdn.rewardnetwork.vn",
        apiUrl: "https://api.rewardnetwork.vn/api/v1",
        mediaUrl: "https://cdn.rewardnetwork.vn",
    },
    system: {
        applicationName: "APP_NAME",
        applicationUrl: ""
    },
    seo: {
        defaultPageTitle: "DEFAULT_TITLE",
        pageTitlePositioning: 10,
        pageTitleSeparator: " | ",
        defaultMetaDescription: "DEFAULT_META_DESCRIPTION",
        defaultMetaKeyword: "DEFAULT_META_KEYWORD",
        defaultMetaImage: "DEFAULT_META_IMAGE"
    },
    i18n: {
        defaultLanguage: {
            code: "vi",
            name: "Vietnamese",
            culture: "vi-VN",
            img: "assets/i18n/flags/vi.png"
        },
        availableLanguages: [
            {
                code: "en",
                name: "English",
                culture: "en-US",
                img: "assets/i18n/flags/en.png"
            },
            {
                code: "vi",
                name: "Vietnamese",
                culture: "vi-VN",
                img: "assets/i18n/flags/vi.png"
            }
        ],
        autoDetectLanguage: true,
        useLocalizedRoutes: true
    },
    googleMap: {
        apiKey: "AIzaSyCsiAtjeWhUeb0YD8W4y4F3vggOQYcO_yU",
        latitude: 10.740280,
        longitude: 106.735930,
        zoom: 5
    },
    firebase: {
        // apiKey: "AIzaSyAF38JlH_yWqNqMXlQU0bUjsQwcEbAVseE",
        // authDomain: "reward-dev-dab11.firebaseapp.com",
        // databaseURL: "https://reward-dev-dab11.firebaseio.com",
        // projectId: "reward-dev-dab11",
        // storageBucket: "reward-dev-dab11.appspot.com",
        // messagingSenderId: "412989730231",
        // appId: "1:412989730231:web:2cd98b353997ac7e178c32",
        // measurementId: "G-0M62J2HQT5",
        apiKey: "AIzaSyCUdPN5o0kmKMkDmbwJpoMrmVzz3lnOI_k",
        authDomain: "reward-network-f741c.firebaseapp.com",
        databaseURL: "https://reward-network-f741c.firebaseio.com",
        projectId: "reward-network-f741c",
        storageBucket: "reward-network-f741c.appspot.com",
        messagingSenderId: "67164225934",
        appId: "1:67164225934:web:2de069ace107abbf0764df",
        measurementId: "G-M7SDVMCLKH"
    }
};