import { JsonMapper } from '../modules/mapper/json.mapper';
import { TransferHttp } from '../modules/transfer-http/transfer-http';
import { UtilHelper } from '../helpers/util.helper';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import {
  PaginationModel,
  ProductModel,
  ReportOrderUserModel,
  ReportProductModel,
  ReportUserModel,
  ReportOrderLocationModel,
  ReportOrderProductModel
} from '../models';
import { REST_API } from '../modules/constants';

@Injectable()
export class ReportService extends BaseService {

  constructor(public http: TransferHttp) {
    super(http);
  }

  public dashboardCountTotal(filter: any = {}): Promise<any> {
    let queryString = UtilHelper.parseFilterToString(filter);
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.DASHBOARD.COUNT_TOTAL + '?' + queryString)
      .then((res) => {
        return res;
      });
  }

  public dashboardCountOrder(filter: any = {}): Promise<any> {
    let queryString = UtilHelper.parseFilterToString(filter);
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.DASHBOARD.COUNT_ORDER + '?' + queryString)
      .then((res) => {
        return res;
      });
  }

  public dashboardTopSaleItem(paging: boolean = true, filter: any = {}): Promise<PaginationModel> {
    if (!paging) {
      filter.offset = '';
      filter.limit = '';
    }
    let queryString = UtilHelper.parseFilterToString(filter);
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.DASHBOARD.TOP_SALE_ITEMS + '?' + queryString)
      .then((res) => {
        res.data = JsonMapper.deserialize(ProductModel, res.data);
        return res;
      });
  }

  public dashboardRecentlySaleItem(paging: boolean = true, filter: any = {}): Promise<PaginationModel> {
    if (!paging) {
      filter.offset = '';
      filter.limit = '';
    }
    let queryString = UtilHelper.parseFilterToString(filter);
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.DASHBOARD.RECENTLY_SALE_ITEMS + '?' + queryString)
      .then((res) => {
        return res;
      });
  }

  public dashboardRevenueByYear(filter: any = {}): Promise<any> {
    let queryString = UtilHelper.parseFilterToString(filter);
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.DASHBOARD.REVENUE_BY_YEAR + '?' + queryString)
      .then((res) => {
        return res;
      });
  }

  public reportOrderUser(filter: any = {}): Promise<any> {
    let queryString = UtilHelper.parseFilterToString(filter);
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.REPORT.ORDER_USER + '?' + queryString)
      .then((res) => {
        res.data = JsonMapper.deserialize(ReportOrderUserModel, res.data);
        return res;
      });
  }

  public reportOrderProduct(filter: any = {}): Promise<any> {
    let queryString = UtilHelper.parseFilterToString(filter);
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.REPORT.ORDER_PRODUCT + '?' + queryString)
      .then((res) => {
        res.data = JsonMapper.deserialize(ReportOrderProductModel, res.data);
        return res;
      });
  }

  public reportOrderLocation(filter: any = {}): Promise<any> {
    let queryString = UtilHelper.parseFilterToString(filter);
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.REPORT.ORDER_LOCATION + '?' + queryString)
      .then((res) => {
        res.data = JsonMapper.deserialize(ReportOrderLocationModel, res.data);
        return res;
      });
  }

  public reportProduct(filter: any = {}): Promise<any> {
    let queryString = UtilHelper.parseFilterToString(filter);
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.REPORT.PRODUCT + '?' + queryString)
      .then((res) => {
        res.data = JsonMapper.deserialize(ReportProductModel, res.data);
        return res;
      });
  }


  public reportUser(filter: any = {}): Promise<any> {
    let queryString = UtilHelper.parseFilterToString(filter);
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.REPORT.USER + '?' + queryString)
      .then((res) => {
        res.data = JsonMapper.deserialize(ReportUserModel, res.data);
        return res;
      });
  }

  
  public reset(): Promise<any> {
    return this.makeHttpPost(`${this.apiUrl}/` + REST_API.REPORT.RESET)
      .then((res) => {
        return res;
      });
  }
}