import { BaseModel } from './base.model';
import { Json } from '../modules';
import { ValidateModel } from './validate.model';

export class AppVersionModel extends BaseModel {
    @Json('platform')
    public platform: string = undefined;

    @Json('version')
    public version: number = undefined;

    @Json('isLatest')
    public isLatest: boolean = undefined;

    @Json('forceUpdate')
    public forceUpdate: boolean = undefined;

    constructor() {
        super();
        this.validateRules = new ValidateModel();
        this.initValidateRules();
    }

    /**
     * init validate rule
     * @returns {ValidateModel}
     */
    public initValidateRules(): ValidateModel {

        this.addRule('platform', 'required', true, this._t('Platform can not be blank.'));
        this.addRule('version', 'required', true, this._t('Version can not be blank.'));
        return this.getRules();
    }

    public static toRequest(data: AppVersionModel): AppVersionModel {
        let model: any = {};

        if (data.id) {
            model.id = data.id;
        }

        model.platform = data.platform;
        model.version = data.version;
        model.isLatest = data.isLatest ? true : false;
        model.forceUpdate = data.forceUpdate ? true : false;

        delete model.validateRules;
        return model;
    }
}