import { BaseModel } from './base.model';
import { ValidateModel } from './validate.model';
import { Json } from '../modules';
import { PointTypeModel } from './point_type.model';
import { UserModel } from './user.model';

export class PointModel extends BaseModel {

  @Json("userId")
  public userId: string = undefined;

  @Json("point")
  public point: string = undefined;

  @Json("pointTypeId")
  public pointTypeId: string = undefined;

  @Json("totalAmount")
  public totalAmount: number = undefined;

  @Json("upgradeAmountRemaining")
  public upgradeAmountRemaining: number = undefined;

  @Json("upgradedDate")
  public upgradedDate: string = undefined;

  @Json({ name: "pointType", clazz: PointTypeModel, omitEmpty: true })
  public pointType: PointTypeModel = undefined;

  @Json({ name: "user", clazz: UserModel, omitEmpty: true })
  public user: UserModel = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  /**
   * init validate rule
   * @returns {ValidateModel}
   */
  public initValidateRules(): ValidateModel {

    return this.getRules();
  }
}