import { ROLE, PAGINATION } from '../../modules/constants';
import {
  MIN_AUTO_COMPLETE_FILTER_CHAR,
  DEPLAY_AUTO_COMPLETE_FILTER_CHAR
} from '../../modules/constants';
import {
  Component,
  ViewEncapsulation,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  ViewChild
} from '@angular/core';
import { distinctUntilChanged, debounceTime, switchMap, filter } from 'rxjs/operators';
import { Subject, of, concat } from 'rxjs';
import { Router, ActivatedRoute, Event } from '@angular/router';
import {
  ProductService
} from '../../services';
import {
  ProductModel, SearchProductModel
} from '../../models';
import { BaseComponent } from '../../pages/base.component';
import { MetaService } from '@ngx-meta/core';
import { Location } from '@angular/common';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JsonMapper } from 'app/modules';

@Component({
  selector: 'app-product-filter',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './product_filter.component.html',
  providers: [
    ProductService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProductFilterComponent),
      multi: true
    }
  ]
})

export class ProductFilterComponent extends BaseComponent implements OnInit, ControlValueAccessor {
  @Output() change: EventEmitter<ProductModel[]> = new EventEmitter<ProductModel[]>();
  @Input() placeholder: string;
  @Input() module: string;
  @Input() search: SearchProductModel;
  public product: ProductModel;
  public products: ProductModel[];
  @Input() selected: ProductModel[] = [];
  public productSelected: ProductModel[] = [];
  public productDiscount: ProductModel[] = [];
  public filterInput = new Subject<string>();
  private propagateChange = (_: any) => { };
  public defaultList: ProductModel[];
  @ViewChild('productModal') private productModal;

  constructor(
    protected _router: Router,
    protected _route: ActivatedRoute,
    protected _meta: MetaService,
    protected _location: Location,
    protected _toastr: ToastrService,
    protected productService: ProductService,
    private modalService: NgbModal
  ) {
    super(_router, _route, _meta, _location, _toastr);
  }

  ngOnInit() {
    this.product = new ProductModel();
    this.search = new SearchProductModel();
    this.itemsPerPage = PAGINATION.ITEM_PRODUCT_FILTER_PAGE;
    this.search.limit = PAGINATION.ITEM_PRODUCT_FILTER_PAGE;
  }

  writeValue(value: any) {
    if (value) {
      this.product = value;
    } else {
      this.product = new ProductModel();
    }
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
  }

  public findAll(): any {
    try {
      this.productService.findAll(true, this.search)
        .then((response) => {
          this.totalItems = response.totalItems;
          this.products = response.data;
          this.productDiscount = this.products.filter(item => item.isDiscount == true);
        })
        .catch((error) => {
          this.setError(error);
        });
    } catch (error) {
      this.setError(error);
    }
  }

  public showProductModal(isShow: boolean = true) {
    this.productSelected = this.selected.map(item => JsonMapper.deserialize(ProductModel, item));
    if (isShow) {
      this.findAll();
      this.modalService.open(this.productModal, { backdrop: 'static', size: 'lg', windowClass: "product-modal" });
    } else {
      this.productSelected = [];
      this.modalService.dismissAll();
    }
  }

  public selectProduct(product: ProductModel) {
    const productSelected = this.productSelected.find(item => item.id === product.id);
    const productDiscount = this.productDiscount.find(item => item.id === product.id);

    if (this.module === 'promotion') {
      if (productDiscount && productSelected.id != productDiscount.id) {
        return false; // prevent choose
      }
    }

    if (productSelected) {
      this.productSelected = this.productSelected.filter(item => item.id !== product.id);
    } else {
      this.productSelected.push(product);
    }
  }

  public confirm() {
    this.change.emit(this.productSelected);
    this.propagateChange(this.productSelected);
    this.showProductModal(false);
  }

  public checkProductSelected(id: string) {
    const product = this.productSelected.find(item => item.id === id);
    return product ? true : false;
  }

  public checkProductDiscount(id: string) {
    if (this.module === 'promotion') {
      const product = this.productDiscount.find(item => item.id === id);
      return product ? true : false;
    }
    return false;
  }
}