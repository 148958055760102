import { JsonMapper } from '../modules/mapper/json.mapper';
import { BaseModel, JsonDate } from './base.model';
import {
  INPUT_MAXLENGTH,
} from '../modules/constants';
import { ValidateModel } from './validate.model';
import { Json } from '../modules';
import { RoleModel } from './role.model';
import { MediaModel } from './media.model';

export class UserModel extends BaseModel {
  @Json('email')
  public email: string = undefined;

  @Json('oldPassword')
  public oldPassword: string = undefined;

  @Json('newPassword')
  public newPassword: string = undefined;

  @Json('password')
  public password: string = undefined;

  @Json('passwordAgain')
  public passwordAgain: string = undefined;

  @Json('name')
  public name: string = undefined;

  @Json('roleId')
  public roleId: string = undefined;

  @Json('phoneNumber')
  public phoneNumber: string = undefined;

  @Json('gender')
  public gender: string = undefined;

  @Json('dayOfBirth')
  public dayOfBirth: string = undefined;

  @Json({ name: 'avatar', clazz: MediaModel, omitEmpty: true })
  public avatar: MediaModel = undefined;

  @Json('avatarId')
  public avatarId: string = undefined;

  @Json('shopId')
  public shopId: string = undefined;

  @Json({ name: 'role', clazz: RoleModel, omitEmpty: true, ignore: true })
  public role: RoleModel = undefined;

  @Json('isEncrypted')
  public isEncrypted: boolean = false;

  @Json('pin')
  public pin: any = undefined;
  
  @Json('shopName')
  public shopName: string = undefined;

  @Json('website')
  public website: string = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  /**
   * init validate rule
   * @returns {ValidateModel}
   */
  public initValidateRules(): ValidateModel {
    this.addRule('firstName', 'required', true, this._t('Tên không được bỏ trống.'));
    this.addRule('lastName', 'required', true, this._t('Họ không được bỏ trống.'));
    this.addRule('name', 'required', true, this._t('Họ và tên không được bỏ trống.'));

    this.addRule('phoneNumber', 'required', true, this._t('Sô điện thoại không được bỏ trống.'));

    // this.addRule('email', 'required', true, this._t('Email không được bỏ trống.'));
    this.addRule('email', 'email', true, this._t('Email không đúng định dạng.'));

    this.addRule('oldPassword', 'required', true, this._t('Mật khẩu cũ không được bỏ trống.'));
    this.addRule('newPassword', 'required', true, this._t('Mật khẩu mới không được bỏ trống.'));
    this.addRule('password', 'required', true, this._t('Mật khẩu không được bỏ trống.'));
    this.addRule('passwordAgain', 'equalTo', '#password', this._t('Mật khẩu xác nhận không đúng.'));
    this.addRule('roleId', 'required', true, this._t('Loại tài khoản không được bỏ trống.'));
    this.addRule('avatarUrl', 'required', true, this._t('Hình ảnh không được bỏ trống.'));
    this.addRule('shopName', 'required', true, this._t('Tên cửa hàng không được bỏ trống.'));

    return this.getRules();
  }

  /**
   *
   * @param data
   * @returns {UserModel}
   */
  public static toProfileModel(data: UserModel): UserModel {
    const model = JsonMapper.deserialize(UserModel, data);
    delete model.validateRules; // remove object validate rules
    return model;
  }
}