import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CustomizerComponent } from './customizer/customizer.component';
import { NotificationSidebarComponent } from './notification-sidebar/notification-sidebar.component';
import { FormsModule } from '@angular/forms';
import { AuthGuard, BaseService } from 'app/services';
import { Ng2PaginationModule } from 'ng2-pagination';
import { AppModal } from 'app/modules';
import { UiSwitchModule } from 'ngx-ui-switch';
import { CKEditorModule } from 'ng2-ckeditor';
import { Angular2AirDatepickerModule } from 'angular2-air-datepicker';
import { DateRangePickerDirective } from './directives/date_range_picker.directive';
import { SanitizeHtmlPipe, PricePipe } from './pipes';
import { NgSelectModule } from '@ng-select/ng-select';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ColorPickerModule } from 'ngx-color-picker';
// DIRECTIVES
import { ToggleFullscreenDirective } from './directives/toggle-fullscreen.directive';
import { SidebarDirective } from './directives/sidebar.directive';
import { SidebarLinkDirective } from './directives/sidebarlink.directive';
import { SidebarListDirective } from './directives/sidebarlist.directive';
import { SidebarAnchorToggleDirective } from './directives/sidebaranchortoggle.directive';
import { SidebarToggleDirective } from './directives/sidebartoggle.directive';
import { DateTimePickerDirective } from './directives/datetime_picker.directive';
import { TruncatePipe } from './pipes/truncate.pipe';
import { ChartsModule } from 'ng2-charts';
import { InputMaskDirective } from './directives/input_mask.directive';
import { MoneyMaskDirective } from './directives/money_mask.directive';
import { ColorFilterComponent } from './color_filter/color_filter.component';
import { UploadImageComponent } from './uploader/upload_image.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { UserFilterComponent } from './user_filter/user_filter.component';
import { ProductFilterComponent } from './product_filter/product_filter.component';
import { SupplierFilterComponent } from './supplier_filter/supplier_filter.component';
@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        TranslateModule,
        FormsModule,
        Ng2PaginationModule,
        UiSwitchModule,
        CKEditorModule,
        Angular2AirDatepickerModule,
        NgSelectModule,
        PerfectScrollbarModule,
        ChartsModule,
        SlickCarouselModule,
        ColorPickerModule,
        ImageCropperModule
    ],
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        CustomizerComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        NgbModule,
        TranslateModule,
        FormsModule,
        Ng2PaginationModule,
        UiSwitchModule,
        CKEditorModule,
        Angular2AirDatepickerModule,
        DateRangePickerDirective,
        SanitizeHtmlPipe,
        PricePipe,
        ToggleFullscreenDirective,
        SidebarDirective,
        SidebarLinkDirective,
        SidebarListDirective,
        SidebarAnchorToggleDirective,
        SidebarToggleDirective,
        DateTimePickerDirective,
        TruncatePipe,
        ChartsModule,
        InputMaskDirective,
        MoneyMaskDirective,
        ColorPickerModule,
        ColorFilterComponent,
        UploadImageComponent,
        ImageCropperModule,
        UserFilterComponent,
        ProductFilterComponent,
        SupplierFilterComponent
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        CustomizerComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        DateRangePickerDirective,
        SanitizeHtmlPipe,
        PricePipe,
        ToggleFullscreenDirective,
        SidebarDirective,
        SidebarLinkDirective,
        SidebarListDirective,
        SidebarAnchorToggleDirective,
        SidebarToggleDirective,
        DateTimePickerDirective,
        TruncatePipe,
        InputMaskDirective,
        MoneyMaskDirective,
        ColorFilterComponent,
        UploadImageComponent,
        UserFilterComponent,
        ProductFilterComponent,
        SupplierFilterComponent
    ],
    providers: [
        AuthGuard,
        AppModal,
        BaseService
    ]
})
export class SharedModule { }
