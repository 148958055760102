import { BaseModel } from './base.model';
import * as moment from 'moment';
import { Json } from '../modules';

export class ReportUserModel extends BaseModel {
  @Json("reportDate")
  public reportDate: string = undefined;

  @Json("reportMonth")
  public reportMonth: number = undefined;

  @Json("reportYear")
  public reportYear: number = undefined;

  @Json("totalAmount")
  public totalAmount: number = 0;
}
