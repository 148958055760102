import { JsonMapper } from '../modules/mapper/json.mapper';
import { ResponseModel, PaginationModel, PromotionModel } from '../models';
import { UtilHelper } from '../helpers/util.helper';
import { TransferHttp } from '../modules/transfer-http/transfer-http';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { REST_API } from '../modules/constants';

@Injectable()
export class PromotionService extends BaseService {
  constructor(public http: TransferHttp) {
    super(http);
  }

  /**
   * Get list Promotion
   * @returns {Promise<T>|Promise<U>}
   */
  public findAll(paging: boolean = true, filter: any = {}): Promise<PaginationModel> {

    if (!paging) {
      filter.offset = '';
      filter.limit = '';
    }

    const queryString = UtilHelper.parseFilterToString(filter);
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.PROMOTION + '?' + queryString)
      .then((res) => {

        res.data = JsonMapper.deserialize(PromotionModel, res.data);
        return res;
      });
  }


  /**
   * Get Promotion detail
   * @returns {Promise<T>|Promise<U>}
   */
  public findById(id, filter: any = {}): Promise<PromotionModel> {
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.PROMOTION + '/' + id)
      .then((res) => {
        return JsonMapper.deserialize(PromotionModel, res);
      });
  }


  /**
   * create Promotion
   * @returns {Promise<T>|Promise<U>}
   */
  public create(data: PromotionModel): Promise<ResponseModel> {
    return this.makeHttpPost(`${this.apiUrl}/` + REST_API.PROMOTION, JsonMapper.serialize(data));
  }

  /**
   * edit Promotion
   * @returns {Promise<T>|Promise<U>}
   */
  public update(data: PromotionModel): Promise<ResponseModel> {
    return this.makeHttpPut(`${this.apiUrl}/` + REST_API.PROMOTION + '/' + data.id, JsonMapper.serialize(data));
  }

  /**
   * delete Promotion
   * @returns {Promise<T>|Promise<U>}
   */
  public delete(id: string): Promise<ResponseModel> {
    return this.makeHttpDelete(`${this.apiUrl}/` + REST_API.PROMOTION + '/' + id);
  }
}
