import { UserModel } from './user.model';
import { BaseModel } from './base.model';
import * as moment from 'moment';
import { Json } from '../modules';

export class ReportOrderUserModel extends BaseModel {
  @Json("reportDate")
  public reportDate: string = undefined;

  @Json("reportMonth")
  public reportMonth: number = undefined;

  @Json("reportYear")
  public reportYear: number = undefined;

  @Json("userId")
  public userId: string = undefined;

  @Json({ name: "user", clazz: UserModel, omitEmpty: true })
  public user: UserModel = undefined;

  @Json("totalAmount")
  public totalAmount: number = undefined;

  @Json("totalPrice")
  public totalPrice: number = undefined;

  @Json("totalAmountBuy")
  public totalAmountBuy: number = undefined;

  @Json("totalPriceBuy")
  public totalPriceBuy: number = undefined;

  @Json("totalAmountTry")
  public totalAmountTry: number = undefined;

  @Json("totalPriceTry")
  public totalPriceTry: number = undefined;

  @Json("totalAmountSupplierBook")
  public totalAmountSupplierBook: number = undefined;

  @Json("totalPriceSupplierBook")
  public totalPriceSupplierBook: number = undefined;

  @Json("totalAmountUserBook")
  public totalAmountUserBook: number = undefined;

  @Json("totalPriceUserBook")
  public totalPriceUserBook: number = undefined;
}
