import { UserModel } from './user.model';
import { BaseModel } from './base.model';
import * as moment from 'moment';
import { Json } from '../modules';
import { ProductModel } from './product.model';
import { CategoryModel } from './category.model';

export class ReportOrderProductModel extends BaseModel {
  @Json("reportDate")
  public reportDate: string = undefined;

  @Json("reportMonth")
  public reportMonth: number = undefined;

  @Json("reportYear")
  public reportYear: number = undefined;

  @Json("productId")
  public productId: string = undefined;

  @Json({ name: "product", clazz: ProductModel, omitEmpty: true })
  public product: ProductModel = undefined;

  @Json("categoryId")
  public categoryId: string = undefined;

  @Json({ name: "category", clazz: CategoryModel, omitEmpty: true })
  public category: CategoryModel = undefined;

  @Json("totalAmount")
  public totalAmount: number = undefined;

  @Json("totalPrice")
  public totalPrice: number = undefined;

  @Json("totalSupplierReceived")
  public totalSupplierReceived: number = undefined;
}
