import { BaseModel } from "./base.model";
import { ProductSizeModel } from "./product_size.model";
import { Json } from "app/modules";

export class WarehouseTrackingModel extends BaseModel {
    @Json("isEnable")
    public isEnable: boolean = undefined;

    @Json("productSizeId")
    public productSizeId: string = undefined;

    @Json({ name: "productSize", clazz: ProductSizeModel, omitEmpty: true })
    public productSize: ProductSizeModel = undefined;

    @Json("quantity")
    public quantity: number = undefined;

    @Json("type")
    public type: string = undefined;

    @Json("name")
    public name: string = undefined;

    @Json("code")
    public code: string = undefined;
}
