import { JsonMapper } from '../modules/mapper/json.mapper';
import { ResponseModel, PaginationModel, AddressBookModel } from '../models';
import { UtilHelper } from '../helpers/util.helper';
import { TransferHttp } from '../modules/transfer-http/transfer-http';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { REST_API } from '../modules/constants';

@Injectable()
export class AddressBookService extends BaseService {
  constructor(public http: TransferHttp) {
    super(http);
  }

  /**
   * Get list AddressBook
   * @returns {Promise<T>|Promise<U>}
   */
  public findAll(paging: boolean = true, filter: any = {}): Promise<PaginationModel> {

    if (!paging) {
      filter.offset = '';
      filter.limit = '';
    }

    const queryString = UtilHelper.parseFilterToString(filter);
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.ADDRESS_BOOK + '?' + queryString)
      .then((res) => {

        res.data = JsonMapper.deserialize(AddressBookModel, res.data);
        return res;
      });
  }


  /**
   * Get AddressBook detail
   * @returns {Promise<T>|Promise<U>}
   */
  public findById(id, filter: any = {}): Promise<AddressBookModel> {
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.ADDRESS_BOOK + '/' + id)
      .then((res) => {
        return JsonMapper.deserialize(AddressBookModel, res);
      });
  }


  /**
   * create AddressBook
   * @returns {Promise<T>|Promise<U>}
   */
  public create(data: AddressBookModel): Promise<ResponseModel> {
    return this.makeHttpPost(`${this.apiUrl}/` + REST_API.ADDRESS_BOOK + '/admin/create', JsonMapper.serialize(data));
  }

  public checkout(data: AddressBookModel): Promise<ResponseModel> {
    return this.makeHttpPost(`${this.apiUrl}/` + REST_API.ADDRESS_BOOK + '/admin/checkout', JsonMapper.serialize(data));
  }

  /**
   * edit AddressBook
   * @returns {Promise<T>|Promise<U>}
   */
  public update(data: AddressBookModel): Promise<ResponseModel> {
    return this.makeHttpPut(`${this.apiUrl}/` + REST_API.ADDRESS_BOOK + '/' + data.id, JsonMapper.serialize(data));
  }

  /**
   * delete AddressBook
   * @returns {Promise<T>|Promise<U>}
   */
  public delete(id: string): Promise<ResponseModel> {
    return this.makeHttpDelete(`${this.apiUrl}/` + REST_API.ADDRESS_BOOK + '/' + id);
  }
}
