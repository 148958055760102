import { JsonMapper } from '../modules/mapper/json.mapper';
import { ResponseModel, PaginationModel, SizeModel, TokenModel } from '../models';
import { UtilHelper } from '../helpers/util.helper';
import { TransferHttp } from '../modules/transfer-http/transfer-http';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { REST_API } from '../modules/constants';

@Injectable()
export class SizeService extends BaseService {
  constructor(public http: TransferHttp) {
    super(http);
  }

  /**
   * Get list Size
   * @returns {Promise<T>|Promise<U>}
   */
  public findAll(paging: boolean = true, filter: any = {}): Promise<PaginationModel> {

    if (!paging) {
      filter.offset = '';
      filter.limit = '';
    }

    const queryString = UtilHelper.parseFilterToString(filter);
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.SIZE + '?' + queryString)
      .then((res) => {

        res.data = JsonMapper.deserialize(SizeModel, res.data);
        return res;
      });
  }

  /**
   * Get Size detail
   * @returns {Promise<T>|Promise<U>}
   */
  public findById(id, filter: any = {}): Promise<SizeModel> {
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.SIZE + '/' + id)
      .then((res) => {
        return JsonMapper.deserialize(SizeModel, res);
      });
  }


  /**
   * create Size
   * @returns {Promise<T>|Promise<U>}
   */
  public create(data: SizeModel): Promise<ResponseModel> {
    return this.makeHttpPost(`${this.apiUrl}/` + REST_API.SIZE , JsonMapper.serialize(data));
  }

  /**
   * edit Size
   * @returns {Promise<T>|Promise<U>}
   */
  public update(data: SizeModel): Promise<ResponseModel> {
    return this.makeHttpPut(`${this.apiUrl}/` + REST_API.SIZE + '/' + data.id, JsonMapper.serialize(data));
  }

  /**
   * delete Size
   * @returns {Promise<T>|Promise<U>}
   */
  public delete(id: string): Promise<ResponseModel> {
    return this.makeHttpDelete(`${this.apiUrl}/` + REST_API.SIZE + '/' + id);
  }
}
