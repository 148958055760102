
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { AgmCoreModule } from '@agm/core';
import { ModalModule } from 'ngx-modialog';
import { BootstrapModalModule } from 'ngx-modialog/plugins/bootstrap';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StoreModule } from '@ngrx/store';
import { DragulaModule } from 'ng2-dragula';
import { ConfigModule, ConfigLoader, ConfigService, ConfigStaticLoader } from '@ngx-config/core';
import { MetaLoader, MetaModule, MetaStaticLoader } from '@ngx-meta/core';
import { AppComponent } from './app.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { FullLayoutComponent } from './layouts/full/full-layout.component';

import { AuthService } from './shared/auth/auth.service';
import { AuthGuard } from './shared/auth/auth-guard.service';
import { setting } from './configs/setting';
import { TransferHttpModule } from './modules/transfer-http/transfer-http.module';
import { AppInterceptor } from './app-interceptor';
import { ReactiveFormsModule } from '@angular/forms';
import { AccountStateProvider } from './pages/account/account.state.provider';
import { AccountReducer } from './pages/account/account.reducer';
import { ReduxModule } from '@harmowatch/ngx-redux-core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireMessagingModule } from '@angular/fire/messaging';

import {
    PerfectScrollbarModule,
    PERFECT_SCROLLBAR_CONFIG,
    PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';


export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function configFactory(): ConfigLoader {
    return new ConfigStaticLoader(setting);
}

export function metaFactory(config: ConfigService, translate: TranslateService): MetaLoader {
    return new MetaStaticLoader({
        callback: (key: string) => translate.get(key),
        pageTitlePositioning: config.getSettings('seo.pageTitlePositioning'),
        pageTitleSeparator: config.getSettings('seo.pageTitleSeparator'),
        applicationName: config.getSettings('system.applicationName'),
        applicationUrl: config.getSettings('system.applicationUrl'),
        defaults: {
            title: config.getSettings('seo.defaultPageTitle'),
            description: config.getSettings('seo.defaultMetaDescription'),
            generator: config.getSettings('seo.defaultPageTitle'),
            'twitter:title': config.getSettings('seo.defaultPageTitle'),
            'twitter:description': config.getSettings('seo.defaultMetaDescription'),
            'twitter:image': config.getSettings('seo.defaultMetaImage'),
            'twitter:card': 'summary',
            'og:title': config.getSettings('seo.defaultPageTitle'),
            'og:description': config.getSettings('seo.defaultMetaDescription'),
            'og:type': 'article',
            'og:image': config.getSettings('seo.defaultMetaImage'),
            'og:site_name': config.getSettings('system.applicationName'),
            'og:locale': config.getSettings('i18n.defaultLanguage.culture'),
            'og:locale:alternate': config.getSettings('i18n.availableLanguages')
                .map((language: any) => language.culture).toString(),

        }
    });
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false
};



@NgModule({
    declarations: [
        AppComponent,
        FullLayoutComponent,
        ContentLayoutComponent
    ],
    imports: [
        BrowserAnimationsModule,
        StoreModule.forRoot({}),
        AppRoutingModule,
        SharedModule,
        DragulaModule.forRoot(),
        HttpClientModule,
        ToastrModule.forRoot(),
        NgbModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        AgmCoreModule.forRoot({
            apiKey: setting.googleMap.apiKey
        }),
        ConfigModule.forRoot({
            provide: ConfigLoader,
            useFactory: (configFactory),
        }),
        MetaModule.forRoot({
            provide: MetaLoader,
            useFactory: (metaFactory),
            deps: [ConfigService, TranslateService]
        }),
        TransferHttpModule,
        ReactiveFormsModule,
        ReduxModule.forRoot({
            state: {
                provider: AccountStateProvider,
                reducers: [AccountReducer],
            }
        }),
        ModalModule.forRoot(),
        BootstrapModalModule,
        NgxSpinnerModule,
        PerfectScrollbarModule,
        AngularFireModule.initializeApp(setting.firebase),
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireMessagingModule,
    ],
    providers: [
        AuthService,
        AuthGuard,
        { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
        AccountStateProvider,
        { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },

    ],
    bootstrap: [AppComponent]
})
export class AppModule {

}
